import { IconButton } from '@brightdrop/bd-ui';
import { useFeatureFlags } from '@brightdrop/feature-flags-client';
import { Box, Button, InputAdornment, Popper, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CarIcon from '~/assets/icons/new/carIcon.svg?react';
import ChevronRight from '~/assets/icons/new/chevron-right.svg?react';
import CloseIcon from '~/assets/icons/new/close_clearInput.svg?react';
import SearchIcon from '~/assets/icons/new/search-sm.svg?react';
import TranslatedLabel from '~/common/components/TranslatedLabel/TranslatedLabel';
import { SearchType as TableSearchType } from '~/common/constants/common.constant';
import useSearchParams from '~/common/hooks/useSearchParams';
import { MOCK_ASSET_LIST } from '~/common/mocks/assets/assetLIst.mock';
import { GLOBAL_SEARCH_FLAG_NAME } from '~/common/models/featureFlags.model';
import {
  FLEET_VEHICLE_LIST,
  FLEET_VEHICLE_LIST_DETAILS,
} from '~/common/models/pages/fleetPages.model';
import { makeRoutePath } from '~/common/utils/route/route.utils';

import {
  GlobalSearchResult,
  GlobalSearchType,
  MAX_GLOBAL_SEARCH_RESULTS,
} from './globalSearch.model';
import useStyles from './GlobalSearch.styles';
import { convertSearchTypeToSearchResultList } from './globalSearch.utils';
import { VehicleSearchResult } from './VehicleSearchResult/VehicleSearchResult';

type UrlSearchParam = { name: string; val: string };

const GlobalSearch = (): JSX.Element => {
  /*** Hooks ***/
  const { classes } = useStyles();
  const history = useHistory();
  const searchParams = useSearchParams();
  const { getFlag } = useFeatureFlags();

  /* Feature flag established 7 Oct 2024 automatically expires 31 Mar 2025 */
  const isGlobalSearchFlagEnabled = getFlag(GLOBAL_SEARCH_FLAG_NAME);

  /*** Placeholder Variables - To be populated by API ***/
  const previousSearchHistory: Array<GlobalSearchResult<GlobalSearchType>> = [];
  const searchResults: Array<GlobalSearchResult<GlobalSearchType>> =
    convertSearchTypeToSearchResultList(MOCK_ASSET_LIST);

  /*** Search Route Paths ***/
  const assetListPath = FLEET_VEHICLE_LIST;
  const assetDetailsPath = FLEET_VEHICLE_LIST_DETAILS;

  /*** State variables ***/
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedResultIndex, setSelectedResultIndex] = useState<number | null>(
    0
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  /*** Refs ***/
  const searchBarRef = useRef<HTMLElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const resetState = (): void => {
    setSearchTerm('');
    setSelectedResultIndex(0);
    setIsPopoverOpen(false);
  };

  /*** Use Effects ***/
  /* After searching or navigating, reset global search to default state. */
  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, searchParams]);

  /* Create event listener to close popper when clicked or focused outside of the popper and textbox */
  useEffect(() => {
    const handleClicksAndFocusOutside = (e: MouseEvent | FocusEvent) => {
      if (
        !popperRef.current?.contains(e.target as Node) &&
        !searchBarRef.current?.parentElement?.contains(e.target as Node)
      ) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClicksAndFocusOutside);
    document.addEventListener('focusin', handleClicksAndFocusOutside);
    return () => {
      document.removeEventListener('mousedown', handleClicksAndFocusOutside);
      document.removeEventListener('focusin', handleClicksAndFocusOutside);
    };
  }, []);

  /*** Helper Functions ***/
  const handleSearch = (route: string, urlParams: Array<UrlSearchParam>) => {
    urlParams.forEach((param) => {
      searchParams[param.name] = param.val;
    });
    history.push(makeRoutePath(route, searchParams, true, true));
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const maxSearchResultIndex = searchResults.length
      ? Math.min(searchResults.length - 1, MAX_GLOBAL_SEARCH_RESULTS - 1)
      : 0;

    if (selectedResultIndex !== null) {
      switch (event.key) {
        case 'Enter':
          if (selectedResultIndex !== null) {
            searchBarRef.current?.blur();
            handleSearch(assetDetailsPath, [
              {
                name: 'assetsId',
                val: searchResults[selectedResultIndex].resultInfo.id,
              },
            ]);
          }
          break;
        case 'ArrowDown':
          if (selectedResultIndex !== maxSearchResultIndex)
            setSelectedResultIndex(selectedResultIndex + 1);
          else setSelectedResultIndex(0);
          break;
        case 'ArrowUp':
          if (selectedResultIndex !== 0)
            setSelectedResultIndex(selectedResultIndex - 1);
          else setSelectedResultIndex(maxSearchResultIndex);
          break;
      }
    }
  };

  /*** Sub Components ***/
  const NoOptions = (): JSX.Element => {
    return (
      <Box className={classes.noOptions}>
        <TranslatedLabel
          translationKey={'common:search.noResults'}
          typographyVariant={'body_medium'}
          component={'span'}
          data-testid="global-search-no-options"
          variables={{
            searchTerm: searchTerm,
          }}
        />
      </Box>
    );
  };

  const SearchResultContainer = (props: {
    searchResults: Array<GlobalSearchResult<GlobalSearchType>>;
  }): JSX.Element => {
    return (
      <>
        <Box className={classes.searchTypeLabel}>
          <CarIcon width={16} height={16} style={{ marginRight: '8px' }} />
          <TranslatedLabel
            translationKey={'common:search.vehicles'}
            typographyVariant="small_body_medium"
          />
        </Box>
        {props.searchResults.map((searchResult, index) => {
          if (index >= MAX_GLOBAL_SEARCH_RESULTS) return;
          return (
            <VehicleSearchResult
              searchTerm={searchTerm}
              searchResult={searchResult}
              selected={selectedResultIndex === index}
              key={index}
              index={index}
              handleNavigation={() => {
                handleSearch(assetDetailsPath, [
                  { name: 'assetsId', val: searchResult.resultInfo.id },
                ]);
              }}
            />
          );
        })}
      </>
    );
  };

  const ViewAllButton = (): JSX.Element => {
    if (searchResults.length > MAX_GLOBAL_SEARCH_RESULTS) {
      return (
        <Button
          variant="text"
          className={classes.viewAllResults}
          onClick={() =>
            handleSearch(assetListPath, [
              { name: 'searchTerm', val: searchTerm },
              { name: 'searchType', val: TableSearchType.ASSET },
            ])
          }
          data-testid="global-search-see-all-results"
        >
          <TranslatedLabel
            translationKey={'common:search.viewAllResults'}
            typographyVariant={'body_medium'}
            variables={{
              amount: searchResults.length,
            }}
          />
          <ChevronRight height={16} width={16} style={{ marginLeft: '4px' }} />
        </Button>
      );
    } else return <></>;
  };

  if (!isGlobalSearchFlagEnabled) return <></>;
  return (
    <>
      <TextField
        data-testid="global-search-searchbar"
        className={classes.searchContainer}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchTerm(e.target.value);
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          handleOnKeyDown(e)
        }
        onFocus={() => {
          setIsPopoverOpen(true);
        }}
        value={searchTerm}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          id: 'global-search-input',
          endAdornment: (
            <>
              {searchTerm && (
                <IconButton
                  onClick={() => setSearchTerm('')}
                  size="small"
                  icon={<CloseIcon />}
                />
              )}
            </>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label={
          !searchTerm && (
            <TranslatedLabel
              translationKey={'common:search.search'}
              typographyVariant={'small_body_regular'}
            />
          )
        }
        inputRef={searchBarRef}
      />

      <Popper
        id="global-search-results"
        ref={popperRef}
        open={
          isPopoverOpen &&
          (searchTerm !== '' || previousSearchHistory.length > 0)
        }
        anchorEl={searchBarRef.current?.parentElement}
        placement={'bottom-start'}
        className={classes.popper}
        data-testid={'global-search-results-container'}
      >
        {/* If the user has entered a search term, show Search Results or No Options text */}
        {searchTerm ? (
          /* If there are search results, display them */
          searchResults ? (
            <>
              <SearchResultContainer searchResults={searchResults} />
              <ViewAllButton />
            </>
          ) : (
            /* If there are no search results, show the No Options message. */
            <NoOptions />
          )
        ) : (
          /* If there is no search term, show previous search history if available.  Otherwise show nothing. */
          previousSearchHistory && (
            <SearchResultContainer searchResults={previousSearchHistory} />
          )
        )}
      </Popper>
    </>
  );
};

export default GlobalSearch;
