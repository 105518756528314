import {
  ActionReducerMapBuilder,
  CaseReducer,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import { DEFAULT_SESSION_CONFIG_KEY } from '~/common/constants/common.constant';
import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';

import {
  mapPeripheralsOnboardResponseToPeripherals,
  mapPeripheralsOnboardStatusResponseToPeripherals,
} from '../mappers/peripheralsMappers';
import {
  PeripheralsSessionConfig,
  PeripheralsSessionViewType,
  PeripheralsState,
  SetSelectedPeripheralsPayload,
} from '../peripheralsSlice.model';
import { PERIPHERALS_ACTIONS } from './peripheralsSlice.actions';
import { peripheralsAdapter } from './peripheralsSlice.adapters';
import { INITIAL_PERIPHERALS_STATE } from './peripheralsSlice.constants';

const reduceSetPeripheralsSessionConfig: CaseReducer<
  PeripheralsState,
  PayloadAction<Partial<PeripheralsSessionConfig>>
> = (state, action) => {
  Object.keys(action.payload).forEach((key) => {
    const scope = key as PeripheralsSessionViewType;
    const payload = action.payload[scope] || {};
    Object.keys(payload).forEach((id) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [scope]: {
            [id]: payload[id] && {
              ...payload[id],
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
  });
};

const reduceResetPeripheralsSessionConfig: CaseReducer<
  typeof INITIAL_PERIPHERALS_STATE,
  PayloadAction<Partial<PeripheralsSessionConfig>>
> = (state, action) => {
  state.sessionConfigs = merge(state.sessionConfigs, {
    [PeripheralsSessionViewType.LIST_VIEW]: {
      [DEFAULT_SESSION_CONFIG_KEY]: {
        importModalOpen: false,
        onboardState: undefined,
        onboardStateStatus: undefined,
      },
    },
  });
};

const buildOnboardPeripheralsReducer = (
  builder: ActionReducerMapBuilder<PeripheralsState>
) => {
  builder.addCase(
    PERIPHERALS_ACTIONS.postPeripheralsOnboard.pending,
    (state, action) => {
      state.sessionConfigs = merge(state.sessionConfigs, {
        [PeripheralsSessionViewType.LIST_VIEW]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            onboardState: {},
            operationStatus: {
              status: BDRequestStatus.PENDING,
            },
          },
        },
      });
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.postPeripheralsOnboard.fulfilled,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              onboardState: mapPeripheralsOnboardResponseToPeripherals(
                action.payload.result
              ),
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.postPeripheralsOnboard.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
              },
              onboardState: {
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildOnboardPeripheralsStatusReducer = (
  builder: ActionReducerMapBuilder<PeripheralsState>
) => {
  builder.addCase(
    PERIPHERALS_ACTIONS.getPeripheralsOnboardStatus.pending,
    (state, action) => {
      state.sessionConfigs = merge(state.sessionConfigs, {
        [PeripheralsSessionViewType.LIST_VIEW]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            onboardStateStatus: {},
            operationStatus: {
              status: BDRequestStatus.PENDING,
            },
          },
        },
      });
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.getPeripheralsOnboardStatus.fulfilled,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              onboardStateStatus:
                mapPeripheralsOnboardStatusResponseToPeripherals(
                  action.payload.result
                ),
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.getPeripheralsOnboardStatus.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
              },
              onboardState: {
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildPostLinkVehicleReducer = (
  builder: ActionReducerMapBuilder<PeripheralsState>
) => {
  builder.addCase(PERIPHERALS_ACTIONS.postLinkVehicle.pending, (state) => {
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [PeripheralsSessionViewType.LIST_VIEW]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            operationStatus: {
              status: BDRequestStatus.PENDING,
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
  builder.addCase(PERIPHERALS_ACTIONS.postLinkVehicle.fulfilled, (state) => {
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [PeripheralsSessionViewType.LIST_VIEW]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
  builder.addCase(
    PERIPHERALS_ACTIONS.postLinkVehicle.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const reduceSetSelectedPeripherals: CaseReducer<
  PeripheralsState,
  PayloadAction<SetSelectedPeripheralsPayload>
> = (state, action) => {
  const { sessionId, selectedPeripherals, removeItems } = action.payload;
  const listViewConfig =
    state.sessionConfigs[PeripheralsSessionViewType.LIST_VIEW];

  if (!listViewConfig || !listViewConfig[sessionId]) {
    return;
  }

  const currentSession = listViewConfig[sessionId];

  if (selectedPeripherals) {
    currentSession.selectedPeripherals = peripheralsAdapter.upsertMany(
      currentSession.selectedPeripherals ||
        peripheralsAdapter.getInitialState(),
      selectedPeripherals
    );
  }

  if (removeItems) {
    currentSession.selectedPeripherals = peripheralsAdapter.removeMany(
      currentSession.selectedPeripherals ||
        peripheralsAdapter.getInitialState(),
      removeItems
    );
  }
};

export const PERIPHERALS_REDUCERS = {
  reduceSetPeripheralsSessionConfig,
  reduceResetPeripheralsSessionConfig,
  reduceSetSelectedPeripherals,
};

export const PERIPHERALS_EXTRA_REDUCER_BUILDERS = {
  buildPostLinkVehicleReducer,
  buildOnboardPeripheralsReducer,
  buildOnboardPeripheralsStatusReducer,
};
