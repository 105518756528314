import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import { NonNullablePick } from '~/common/models/common.model';
import {
  ApiConnectivityStatusSummary,
  ConnectivityStatusSummary,
  DiagnosticSummary,
  RecallSummary,
} from '~/common/models/csDashboard.model';
import { BDError } from '~/common/models/error.model';
import {
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { mapConnectivityStatusSummary } from '../connectivity/connectivity.mappers';

export type CSDashboardSummaryParams = NonNullablePick<
  RouteParams,
  'organizationsId'
> &
  Pick<RouteParams, 'hubsId' | 'fleetsId'> & {
    sessionId: string;
  };

export enum CSDashboardActionType {
  GET_CONNECTIVITY_STATUS_SUMMARY = 'dashboard/getConnectivityStatusSummary',
}

const getConnectivityStatusSummary = makeThunk(
  CSDashboardActionType.GET_CONNECTIVITY_STATUS_SUMMARY,
  makeGetPayloadCreator<
    ApiResponse<ConnectivityStatusSummary>,
    CSDashboardSummaryParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/connectivityStatusDashboardSummary`,
    axiosOptions: ({ organizationsId }, state) =>
      addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationsId),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<ApiConnectivityStatusSummary>(response)) {
        return {
          ...response,
          result: mapConnectivityStatusSummary(response.result),
        };
      }
      throw new BDError('Unexpected Connectivity Status Summary response', {
        data: response,
      });
    },
  })
);

export const getDiagnosticsSummary = makeThunk(
  'csDashboard/fetchDiagnosticsSummary',
  makeGetPayloadCreator<
    ApiResponse<DiagnosticSummary>,
    CSDashboardSummaryParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/diagnosticDashboardSummary`,
    axiosOptions: ({ organizationsId }, state) =>
      addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationsId),

    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<DiagnosticSummary>(response)) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected diagnostic summary response', {
        data: response,
      });
    },
  })
);

export const postRecallSummary = makeThunk(
  'csDashboard/postRecallSummary',
  makePostPayloadCreator<ApiResponse<RecallSummary>, CSDashboardSummaryParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.recallSummary}/fieldactions/vehicleSummary`,

    axiosOptions: ({ organizationsId }, state) =>
      addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationsId),

    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<RecallSummary>(response)) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected Recall Summary response', {
        data: response,
      });
    },
  })
);

export const CS_DASHBOARD_ACTIONS = {
  getConnectivityStatusSummary,
  getDiagnosticsSummary,
  postRecallSummary,
};
