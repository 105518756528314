import { DEFAULT_SESSION_CONFIG_KEY } from '~/common/constants/common.constant';

import {
  PeripheralsSessionViewType,
  PeripheralsState,
} from '../peripheralsSlice.model';
import { peripheralsAdapter } from './peripheralsSlice.adapters';

export const INITIAL_PERIPHERALS_STATE: PeripheralsState = {
  items: peripheralsAdapter.getInitialState(),
  sessionConfigs: {
    [PeripheralsSessionViewType.LIST_VIEW]: {
      [DEFAULT_SESSION_CONFIG_KEY]: {
        selectedIds: [],
        selectedPeripherals: peripheralsAdapter.getInitialState(),
        allSelected: false,
      },
    },
  },
  operations: {},
};
