import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

import Ep1Icon from '~/assets/icons/ep1Icon.svg';
import VehicleIcon from '~/assets/icons/vehicleIcon.svg?react';
import { isValidLocation } from '~/common/components/map/utils/map.utils';
import { TabContent } from '~/common/components/table/BDTable';
import { RouteParams } from '~/common/configs/route.config';
import {
  DEFAULT_EMPTY_VALUE,
  SearchType,
} from '~/common/constants/common.constant';
import {
  ApiAsset,
  Asset,
  AssetConnectivityStatus,
  AssetEvent,
  AssetFlags,
  AssetPubSubEventPayload,
  AssetRequestFieldNames,
  AssetSearchFieldType,
  AssetServiceStatus,
  AssetState,
  AssetTelemetry,
  AssetTrip,
  AssetType,
  OrganizationOwnershipStatus,
  ProvisioningStatus,
} from '~/common/models/asset.model';
import {
  ASSET_REPORT_FIELD_NAMES,
  AssetComplianceFieldType,
  AssetReportFieldType,
  BatteryChargingStatus,
  BatterySocStatus,
  BrakePadStatus,
  FuelStatus,
  LastCommunicatedStatus,
  OilLifeStatus,
  PowerType,
  ReportDiagnosticState,
  SafetyCompliance,
} from '~/common/models/asset-report.model';
import {
  ComponentLabels,
  ConnectivityStatusType,
  SearchCriteria,
} from '~/common/models/common.model';
import {
  BDQuery,
  BDQueryCriteriaType,
  BDSort,
  BDSortDirection,
} from '~/common/models/query.model';
import { MAX_VALID_SOC_VALUE } from '~/common/utils/battery.utils';
import { buildQueryTree } from '~/common/utils/query.utils';

import {
  AssetComplianceFilterCriteria,
  AssetComplianceSession,
  AssetListFilterCriteria,
  AssetListSession,
} from '../assetsSlice';
import { mapAssetApiResponseToAsset } from '../mappers/asset.mappers';
import {
  FUEL_CHARGE_FILTER_MODAL_STATUS,
  MIN_VALID_FUEL_VALUE,
} from '../report/AssetReportView.constant';
import {
  AVAILABLE_LAST_COMMUNICATED_FILTER_OPTIONS,
  AVAILABLE_TIRE_FILTER_OPTIONS,
  BATTERY_READINESS_CHARGING_FILTER_OPTIONS,
  FLEET_READINESS_CHARGING_FILTER_OPTIONS,
} from '../report/configs/AssetListReportViewFilter.config';

export const getAssetsByClass = (
  assets: Asset[],
  currentTab: AssetType | undefined
): Asset[] => {
  switch (currentTab) {
    case AssetType.EP:
      return assets.filter(
        (asset) =>
          asset.type.class &&
          (asset.type.class === AssetType.EP ||
            asset.type.class === AssetType.PERI)
      );
    case AssetType.VEHICLE:
      return assets.filter(
        (asset) => asset.type.class && asset.type.class === AssetType.VEHICLE
      );
    default:
      return assets;
  }
};

export const getMappedAssetsByClass = <
  T extends Asset &
    Partial<AssetState> &
    Partial<AssetTelemetry> &
    Partial<AssetFlags>
>(
  mappedAssets: T[]
): {
  [k in AssetType | 'all']: (T & { originalIndex: number })[];
} => {
  const assetsByClass = {} as {
    [k in AssetType | 'all']: (T & { originalIndex: number })[];
  };
  assetsByClass['all'] = [];
  if (mappedAssets.length) {
    mappedAssets.forEach((asset, index) => {
      if (asset.type.class && asset.type.class === AssetType.VEHICLE) {
        if (!assetsByClass[asset.type.class]) {
          assetsByClass[asset.type.class] = [];
        }
        assetsByClass[asset.type.class] = [
          ...assetsByClass[asset.type.class],
          { ...asset, originalIndex: index },
        ];
      }
      if (
        asset.type.class &&
        (asset.type.class === AssetType.EP ||
          asset.type.class === AssetType.PERI)
      ) {
        if (!assetsByClass[AssetType.EP]) {
          assetsByClass[AssetType.EP] = [];
        }
        assetsByClass[AssetType.EP] = [
          ...assetsByClass[AssetType.EP],
          { ...asset, originalIndex: index },
        ];
      }
      assetsByClass['all'] = [
        ...assetsByClass['all'],
        { ...asset, originalIndex: index },
      ];
    });
  }
  return assetsByClass;
};

export const filterTripLocation = (trip: AssetTrip): boolean => {
  const tripIsDefined = trip.id && trip.start?.value && trip.end?.value;
  const validTripLocations =
    isValidLocation(trip.start?.value) && isValidLocation(trip.end?.value);
  const hasDifferentEndpoints =
    trip.start?.value.latitude !== trip.end?.value.latitude ||
    trip.start?.value.longitude !== trip.end?.value.longitude;
  const vehicleDidMove =
    !trip?.startOdometer ||
    !trip.endOdometer ||
    trip?.startOdometer !== trip?.endOdometer;
  return (
    !isEmpty(tripIsDefined) &&
    validTripLocations &&
    (hasDifferentEndpoints || vehicleDidMove)
  );
};
export const getTripsFromAssetEvents = (events?: AssetEvent[]): AssetTrip[] =>
  events
    ?.reduce((accumulator: AssetTrip[], current) => {
      const lastTrip = accumulator[accumulator.length - 1];
      return (!lastTrip || (lastTrip.start && lastTrip.end)) &&
        current.action?.type === 'Power' &&
        current.action?.value === 'OFF'
        ? [
            ...accumulator,
            {
              id: current.state?.tripId || current.id,
              end: {
                deviceTimestamp: current.timestamp,
                value: {
                  ...current.state.location,
                  address: current.state.address,
                },
              },
              tripId: current.state.tripId,
              startEventId: current.state.startEventId,
              endEventId: current.state.endEventId,
              endOdometer: current.state?.tripEndOdometer,
            } as AssetTrip,
          ]
        : lastTrip?.end &&
          !lastTrip?.start &&
          current.action?.type === 'Power' &&
          current.action?.value === 'ON'
        ? [
            ...accumulator.slice(0, accumulator.length - 1),
            {
              ...lastTrip,
              id: current.state?.tripId || current.id,
              start: {
                deviceTimestamp: current.timestamp,
                value: {
                  ...current.state.location,
                  address: current.state.address,
                },
              },
              tripId: current.state.tripId,
              startEventId: current.state.startEventId,
              endEventId: current.state.endEventId,
              startOdometer: current.state?.tripStartOdometer,
            } as AssetTrip,
          ]
        : accumulator;
    }, [])
    .filter((trip) => filterTripLocation(trip))
    .map((trip, index, array) => {
      const startId = 2 * array.length - 2 * index - 1;
      return {
        ...trip,
        start: {
          ...trip.start,
          value: { ...trip.start.value, id: startId },
        },
        end: {
          ...trip.end,
          value: {
            ...trip.end.value,
            id: startId + 1,
          },
        },
      };
    }) || [];

export const getAssetTabs = (
  tabLabels?: Exclude<ComponentLabels, string>
): TabContent[] => [
  {
    id: 'vehicles-tab',
    icon: <VehicleIcon />,
    label: tabLabels?.['common:zevo'] as string,
    type: AssetType.VEHICLE,
  },
  {
    id: 'pallets-tab',
    icon: <Ep1Icon />,
    label: tabLabels?.['common:trace'] as string,
    type: AssetType.EP,
  },
];

// a) Default selected tab is "VEHICLES" for any asset view showing a list with tabs, or
// b) "EPs" tab should only be selected by default if asset list contains only EP assets; in any other case, default to "VEHICLES"
export const getDefaultAssetListTab = (assets: Asset[]): AssetType => {
  const hasVehicles = assets.some(
    (asset) => asset.type?.class === AssetType.VEHICLE
  );
  const hasEP = assets.some((asset) => asset.type?.class === AssetType.EP);

  if (hasEP && hasVehicles) {
    return AssetType.VEHICLE;
  } else if (hasEP) {
    return AssetType.EP;
  } else {
    return AssetType.VEHICLE;
  }
};

export const isPubSubEventMessage = <T extends AssetPubSubEventPayload>(
  arg: unknown
): arg is T => {
  return !!arg && !!(arg as T).id && !!(arg as T).statuses;
};

export const mergeAssetStates = (
  existing: Asset,
  incoming: AssetPubSubEventPayload
): Asset => {
  // event payload `location` status uses different format compared to asset details, must be handled separately
  const { location: incomingLocation, ...statuses } = incoming.statuses || {};
  const incomingCoordinates = incomingLocation?.value?.coordinates || [];
  const incomingTimestamp = incomingLocation?.deviceTimestamp;

  const mergedApiAsset: Partial<ApiAsset> = {
    ...existing.raw,
    diagnostics: incoming.diagnostics,
    status: {
      ...existing.raw?.status,
      ...statuses,
      location:
        incomingTimestamp &&
        incomingCoordinates?.length === 2 &&
        isValidLocation({
          latitude: incomingCoordinates[1],
          longitude: incomingCoordinates[0],
        })
          ? {
              value: {
                lat: incomingCoordinates[1],
                lon: incomingCoordinates[0],
              },
              deviceTimestamp: incomingTimestamp,
            }
          : existing.raw?.status?.location,
    },
  };

  try {
    const mappedAsset = mapAssetApiResponseToAsset(mergedApiAsset);
    return mappedAsset;
  } catch (e) {
    // do nothing with incoming event; return existing asset
  }
  return existing;
};

export const getAssetTypeDetail = (asset: Asset): string => {
  return asset.type?.model
    ? `${asset.type.model} ${asset.type.version}`
    : DEFAULT_EMPTY_VALUE;
};

export const createAssetQueryTree = (
  queries: BDQuery[]
): BDQuery | undefined => {
  if (queries.length === 0) {
    return undefined;
  }
  return buildQueryTree(queries, 0, queries.length - 1, true);
};

export const createEqualQuery = (
  field: string,
  values: Array<string | boolean>
): BDQuery => createQuery(BDQueryCriteriaType.IS_EQUAL, field, values);

export const createIsNullQuery = (field: string): BDQuery =>
  ({
    qualifier: BDQueryCriteriaType.IS_NULL,
    field,
  } as BDQuery);

export const createStartsWithQuery = (
  field: string,
  values: Array<string | boolean>
): BDQuery => createQuery(BDQueryCriteriaType.STARTS_WITH, field, values);

export const createContainingQuery = (
  field: string,
  values: Array<string>
): BDQuery => createQuery(BDQueryCriteriaType.CONTAINING, field, values);

export const createInQuery = (
  field: string,
  values: Array<string | boolean>
): BDQuery => createQuery(BDQueryCriteriaType.IN, field, values);

export const createQuery = (
  qualifier: BDQueryCriteriaType,
  field: string,
  values: Array<string | boolean>
): BDQuery => {
  return {
    qualifier,
    field,
    values,
  } as BDQuery;
};

export const createAssetFilterQuery = (
  type: AssetSearchFieldType,
  input: Array<string | boolean>
): BDQuery | undefined => {
  let criteria;
  switch (type) {
    case AssetSearchFieldType.HUB_ID:
    case AssetSearchFieldType.FLEET_ID:
    case AssetSearchFieldType.ORG_ID: {
      criteria = {
        qualifier: BDQueryCriteriaType.IS_EQUAL,
        field: type,
        values: input,
      } as BDQuery;
      break;
    }
  }
  return criteria;
};

export const createSearchQuery = (
  searchCriteria: SearchCriteria | undefined,
  fieldNames: AssetRequestFieldNames
): BDQuery | undefined => {
  if (!searchCriteria?.input || !searchCriteria?.searchType) {
    return undefined;
  }
  const values = [searchCriteria.input];
  switch (searchCriteria.searchType) {
    case SearchType.ASSET:
      return createContainingQuery(fieldNames.name, values);
    case SearchType.ID:
    case SearchType.VIN:
    case SearchType.SERIAL_NUMBER:
      return createContainingQuery(fieldNames.id, values);
    case SearchType.LICENSE_PLATE:
      return createContainingQuery(fieldNames.licensePlate, values);
  }
  return undefined;
};

const createDiagnosticQuery = (
  diagnostics: ReportDiagnosticState[],
  fieldName: string
) => {
  const isCritical = diagnostics.find(
    (diagnostic) => diagnostic === ReportDiagnosticState.CRITICAL
  );
  const isGood = diagnostics.find(
    (diagnostic) => diagnostic === ReportDiagnosticState.GOOD
  );
  if (isCritical && isGood) {
    return undefined;
  } else if (isCritical) {
    return createEqualQuery(fieldName, [true]);
  } else if (isGood) {
    return createIsNullQuery(fieldName);
  }
  return undefined;
};
const createSafetyCompliantQuery = (
  compliance: SafetyCompliance[],
  safetyCompliantFieldName: string,
  chargingCompliantFieldName: string,
  isChargeStatusFlagEnabled: boolean
) => {
  const compliant = compliance.find(
    (compliance) => compliance === SafetyCompliance.COMPLIANT
  );
  if (compliant) {
    if (isChargeStatusFlagEnabled) {
      return {
        qualifier: BDQueryCriteriaType.AND,
        leftQuery: {
          qualifier: BDQueryCriteriaType.OR,
          leftQuery: {
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: safetyCompliantFieldName,
            values: [true],
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.IS_NULL,
            field: safetyCompliantFieldName,
          },
        },
        rightQuery: {
          qualifier: BDQueryCriteriaType.OR,
          leftQuery: {
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: chargingCompliantFieldName,
            values: [true],
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.IS_NULL,
            field: chargingCompliantFieldName,
          },
        },
      } as BDQuery;
    } else {
      return {
        qualifier: BDQueryCriteriaType.OR,
        leftQuery: {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: safetyCompliantFieldName,
          values: [true],
        },
        rightQuery: {
          qualifier: BDQueryCriteriaType.IS_NULL,
          field: safetyCompliantFieldName,
        },
      };
    }
  } else {
    if (isChargeStatusFlagEnabled) {
      return {
        qualifier: BDQueryCriteriaType.OR,
        leftQuery: {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: safetyCompliantFieldName,
          values: [false],
        },
        rightQuery: {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: chargingCompliantFieldName,
          values: [false],
        },
      } as BDQuery;
    } else {
      return createQuery(
        BDQueryCriteriaType.IS_EQUAL,
        safetyCompliantFieldName,
        [false]
      );
    }
  }
};

const createChargeStatusQuery = (
  batteryChargingStatus: BatteryChargingStatus[] | undefined,
  powerTypes: PowerType[] | undefined,
  fieldName: string
) => {
  const isNotPluggedIn = batteryChargingStatus?.length
    ? batteryChargingStatus.includes(BatteryChargingStatus.NOT_PLUGGED_IN)
    : false;
  //TODO:Remove the condition when API is ready to handle the power type  for not_plugged_in
  if (powerTypes && powerTypes.length) {
    if (!batteryChargingStatus?.length) {
      return {
        qualifier: BDQueryCriteriaType.OR,
        leftQuery: {
          qualifier: BDQueryCriteriaType.NOT_IN,
          field: fieldName,
          values: [BatteryChargingStatus.NOT_PLUGGED_IN],
        },
        rightQuery: {
          qualifier: BDQueryCriteriaType.IS_NULL,
          field: fieldName,
        },
      } as BDQuery;
    }
    if (isNotPluggedIn) {
      const chargeStatuses = batteryChargingStatus.filter(
        (status) => status !== BatteryChargingStatus.NOT_PLUGGED_IN
      );
      return {
        qualifier: BDQueryCriteriaType.IN,
        field: fieldName,
        values: batteryChargingStatus.length === 1 ? ['--'] : chargeStatuses,
      } as BDQuery;
    }
    if (!isNotPluggedIn) {
      return {
        qualifier: BDQueryCriteriaType.IN,
        field: fieldName,
        values: batteryChargingStatus,
      } as BDQuery;
    }
  } else if (batteryChargingStatus?.length) {
    if (
      batteryChargingStatus.length ===
      BATTERY_READINESS_CHARGING_FILTER_OPTIONS.length
    ) {
      return undefined;
    }
    return createInQuery(fieldName, batteryChargingStatus);
  }
};
const createChargingQuery = (
  batteryChargingStatus: BatteryChargingStatus[],
  fieldName: string
) => {
  if (
    batteryChargingStatus.length ===
    FLEET_READINESS_CHARGING_FILTER_OPTIONS.length
  ) {
    return undefined;
  }
  const isCharging = batteryChargingStatus.find(
    (status) => status === BatteryChargingStatus.CHARGING
  );
  return createEqualQuery(fieldName, [!!isCharging]);
};

const createPowerTypeQuery = (powerTypes: PowerType[], fieldName: string) => {
  if (powerTypes.length === Object.keys(PowerType).length) {
    return undefined;
  }
  return createInQuery(fieldName, powerTypes);
};

export const OFFLINE_MINUTES_THRESHOLD = 10;

const createLastCommunicatedQuery = (
  lastCommunicated: LastCommunicatedStatus[],
  field: string
) => {
  if (
    lastCommunicated.length ===
    AVAILABLE_LAST_COMMUNICATED_FILTER_OPTIONS.length
  ) {
    return undefined;
  }
  const queries = lastCommunicated
    .map((status) => {
      const range = getLastCommunicatedDateRange(status);
      if (status === LastCommunicatedStatus.WITHIN_M1W) {
        return (
          range.start &&
          ({
            qualifier: BDQueryCriteriaType.LESS_THAN,
            field,
            values: [range.start.toISOString()],
          } as BDQuery)
        );
      } else
        return range.end && range.start
          ? ({
              qualifier: BDQueryCriteriaType.AND,
              leftQuery: {
                qualifier: BDQueryCriteriaType.OR,
                leftQuery: {
                  qualifier: BDQueryCriteriaType.GREATER_THAN,
                  field,
                  values: [range.start.toISOString()],
                },
                rightQuery: {
                  qualifier: BDQueryCriteriaType.IS_EQUAL,
                  field,
                  values: [range.start.toISOString()],
                },
              },
              rightQuery: {
                qualifier: BDQueryCriteriaType.LESS_THAN,
                field,
                values: [range.end.toISOString()],
              },
            } as BDQuery)
          : range.end
          ? ({
              qualifier: BDQueryCriteriaType.LESS_THAN,
              field,
              values: [range.end.toISOString()],
            } as BDQuery)
          : range.start
          ? ({
              qualifier: BDQueryCriteriaType.OR,
              leftQuery: {
                qualifier: BDQueryCriteriaType.GREATER_THAN,
                field,
                values: [range.start.toISOString()],
              },
              rightQuery: {
                qualifier: BDQueryCriteriaType.IS_EQUAL,
                field,
                values: [range.start.toISOString()],
              },
            } as BDQuery)
          : undefined;
    })
    .filter((query) => !!query) as BDQuery[];
  return buildQueryTree(queries, 0, queries.length - 1, false);
};

const createTiresQuery = (connectivityStatus: ReportDiagnosticState[]) => {
  if (connectivityStatus.length === AVAILABLE_TIRE_FILTER_OPTIONS.length) {
    return undefined;
  }
  const queries = connectivityStatus.map((status) => {
    switch (status) {
      case ReportDiagnosticState.CRITICAL:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: AssetReportFieldType.FLAT_TIRE,
            values: [true],
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.IS_NULL,
            field: AssetReportFieldType.LOW_TIRE,
          },
        } as BDQuery;
      case ReportDiagnosticState.IMPAIRED:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: AssetReportFieldType.LOW_TIRE,
            values: [true],
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.IS_NULL,
            field: AssetReportFieldType.FLAT_TIRE,
          },
        } as BDQuery;
      default:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.AND,
            leftQuery: {
              qualifier: BDQueryCriteriaType.IS_NULL,
              field: AssetReportFieldType.LOW_TIRE,
            },
            rightQuery: {
              qualifier: BDQueryCriteriaType.IS_NULL,
              field: AssetReportFieldType.FLAT_TIRE,
            },
          },
          //TODO:Remove the condition when API is ready to handle the tire status for null case
          rightQuery: {
            qualifier: BDQueryCriteriaType.AND,
            leftQuery: {
              qualifier: BDQueryCriteriaType.GREATER_THAN,
              field: AssetReportFieldType.LEFT_FRONT_TIRE,
              values: [0],
            },
            rightQuery: {
              qualifier: BDQueryCriteriaType.GREATER_THAN,
              field: AssetReportFieldType.FRONT_EXP_TIRE,
              values: [0],
            },
          },
        } as BDQuery;
    }
  });
  return buildQueryTree(queries, 0, queries.length - 1, false);
};

const createFaultStatusQuery = (
  fieldName: string,
  fault: ReportDiagnosticState[]
) => {
  const isCritical = fault.find(
    (status) => status === ReportDiagnosticState.CRITICAL
  );
  const isImpaired = fault.find(
    (status) => status === ReportDiagnosticState.IMPAIRED
  );
  if (isImpaired && isCritical) {
    return {
      qualifier: BDQueryCriteriaType.OR,
      leftQuery: {
        qualifier: BDQueryCriteriaType.IS_EQUAL,
        field: fieldName,
        values: [ReportDiagnosticState.CRITICAL],
      },
      rightQuery: {
        qualifier: BDQueryCriteriaType.IS_EQUAL,
        field: fieldName,
        values: [ReportDiagnosticState.IMPAIRED],
      },
    };
  } else {
    return createInQuery(fieldName, fault);
  }
};
const createOilLifeQuery = (oilLifeStatus: OilLifeStatus[]) => {
  const queries = oilLifeStatus.map((status) => {
    switch (status) {
      case OilLifeStatus.CRITICAL:
        return {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: AssetReportFieldType.LOW_OIL_LIFE_STATUS,
          values: [true],
        } as BDQuery;
      case OilLifeStatus.IMPAIRED:
        return {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: AssetReportFieldType.MEDIUM_OIL_LIFE_STATUS,
          values: [true],
        } as BDQuery;
      case OilLifeStatus.GOOD:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.AND,
            leftQuery: {
              qualifier: BDQueryCriteriaType.IS_NULL,
              field: AssetReportFieldType.MEDIUM_OIL_LIFE_STATUS,
            },
            rightQuery: {
              qualifier: BDQueryCriteriaType.IS_NULL,
              field: AssetReportFieldType.LOW_OIL_LIFE_STATUS,
            },
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.GREATER_THAN,
            field: AssetReportFieldType.OIL_LIFE_VALUE,
            values: [0],
          },
        } as BDQuery;
      default:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.AND,
            leftQuery: {
              qualifier: BDQueryCriteriaType.IS_EQUAL,
              field: AssetReportFieldType.LOW_OIL_LIFE_STATUS,
              values: [true],
            },
            rightQuery: {
              qualifier: BDQueryCriteriaType.IS_EQUAL,
              field: AssetReportFieldType.MEDIUM_OIL_LIFE_STATUS,
              values: [true],
            },
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.GREATER_THAN,
            field: AssetReportFieldType.OIL_LIFE_VALUE,
            values: [0],
          },
        } as BDQuery;
    }
  });
  return buildQueryTree(queries, 0, queries.length - 1, false);
};

const createBrakePadQuery = (
  brakePadFront: boolean,
  brakePadStatus: BrakePadStatus[]
) => {
  const queries = brakePadStatus.map((status) => {
    switch (status) {
      case BrakePadStatus.CRITICAL:
        return {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: brakePadFront
            ? AssetReportFieldType.LOW_BRAKE_PAD_FRONT_STATUS
            : AssetReportFieldType.LOW_BRAKE_PAD_REAR_STATUS,
          values: [true],
        } as BDQuery;
      case BrakePadStatus.IMPAIRED:
        return {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: brakePadFront
            ? AssetReportFieldType.MEDIUM_BRAKE_PAD_FRONT_STATUS
            : AssetReportFieldType.MEDIUM_BRAKE_PAD_REAR_STATUS,
          values: [true],
        } as BDQuery;
      case BrakePadStatus.GOOD:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.AND,
            leftQuery: {
              qualifier: BDQueryCriteriaType.IS_NULL,
              field: brakePadFront
                ? AssetReportFieldType.MEDIUM_BRAKE_PAD_FRONT_STATUS
                : AssetReportFieldType.MEDIUM_BRAKE_PAD_REAR_STATUS,
            },
            rightQuery: {
              qualifier: BDQueryCriteriaType.IS_NULL,
              field: brakePadFront
                ? AssetReportFieldType.LOW_BRAKE_PAD_FRONT_STATUS
                : AssetReportFieldType.LOW_BRAKE_PAD_REAR_STATUS,
            },
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.GREATER_THAN,
            field: brakePadFront
              ? AssetReportFieldType.BRAKE_PAD_FRONT_VALUE
              : AssetReportFieldType.BRAKE_PAD_FRONT_VALUE,
            values: [0],
          },
        } as BDQuery;
      default:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.AND,
            leftQuery: {
              qualifier: BDQueryCriteriaType.IS_EQUAL,
              field: brakePadFront
                ? AssetReportFieldType.LOW_BRAKE_PAD_FRONT_STATUS
                : AssetReportFieldType.LOW_BRAKE_PAD_REAR_STATUS,
              values: [true],
            },
            rightQuery: {
              qualifier: BDQueryCriteriaType.IS_EQUAL,
              field: brakePadFront
                ? AssetReportFieldType.MEDIUM_BRAKE_PAD_FRONT_STATUS
                : AssetReportFieldType.MEDIUM_BRAKE_PAD_REAR_STATUS,
              values: [true],
            },
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.GREATER_THAN,
            field: brakePadFront
              ? AssetReportFieldType.BRAKE_PAD_FRONT_VALUE
              : AssetReportFieldType.BRAKE_PAD_FRONT_VALUE,
            values: [0],
          },
        } as BDQuery;
    }
  });
  return buildQueryTree(queries, 0, queries.length - 1, false);
};

const createDiagnosticsHealthQuery = (
  diagnosticsHealth: ReportDiagnosticState[],
  field: string
) => {
  const queryValues = diagnosticsHealth.filter(
    (status) =>
      status === ReportDiagnosticState.CRITICAL ||
      status === ReportDiagnosticState.IMPAIRED
  );
  return queryValues.length ? createInQuery(field, queryValues) : undefined;
};
const BINARY_OPTIONS_COUNT = 2;
const createAvailabilityQuery = (
  availability: AssetServiceStatus[],
  field: string
) => {
  if (!availability?.length || availability.length === BINARY_OPTIONS_COUNT) {
    return undefined;
  }
  return createInQuery(field, availability);
};
const createFuelQuery = (
  fuelStatus: FuelStatus[] | BatterySocStatus[],
  field: string
) => {
  const makeInclusiveQuery = (
    value: number,
    isGreaterThan: boolean
  ): BDQuery => {
    return {
      qualifier: BDQueryCriteriaType.OR,
      leftQuery: {
        qualifier: isGreaterThan
          ? BDQueryCriteriaType.GREATER_THAN
          : BDQueryCriteriaType.LESS_THAN,
        field,
        values: [value],
      },
      rightQuery: {
        qualifier: BDQueryCriteriaType.IS_EQUAL,
        field,
        values: [value],
      },
    };
  };
  const queries = fuelStatus
    .map((status) => {
      const { start, end, inclusiveStart, inclusiveEnd } =
        FUEL_CHARGE_FILTER_MODAL_STATUS[status];

      const query: BDQuery = {
        qualifier: BDQueryCriteriaType.AND,
        leftQuery: start
          ? inclusiveStart
            ? makeInclusiveQuery(start, true)
            : {
                qualifier: BDQueryCriteriaType.GREATER_THAN,
                field,
                values: [start],
              }
          : makeInclusiveQuery(MIN_VALID_FUEL_VALUE, true),
        rightQuery: end
          ? inclusiveEnd
            ? makeInclusiveQuery(end, false)
            : {
                qualifier: BDQueryCriteriaType.LESS_THAN,
                field,
                values: [end],
              }
          : makeInclusiveQuery(MAX_VALID_SOC_VALUE, false),
      };
      return [query];
    })
    .flat();
  return buildQueryTree(queries, 0, queries.length - 1, false);
};

const createEngineAirFilterQuery = (
  engineAirFilterStatus: ReportDiagnosticState[]
) => {
  const queries = engineAirFilterStatus.map((status) => {
    switch (status) {
      case ReportDiagnosticState.CRITICAL:
        return {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: AssetReportFieldType.LOW_ENGINE_AIR_FILTER_STATUS,
          values: [true],
        } as BDQuery;
      case ReportDiagnosticState.IMPAIRED:
        return {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: AssetReportFieldType.MEDIUM_ENGINE_AIR_FILTER_STATUS,
          values: [true],
        } as BDQuery;
      case ReportDiagnosticState.GOOD:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.AND,
            leftQuery: {
              qualifier: BDQueryCriteriaType.IS_NULL,
              field: AssetReportFieldType.MEDIUM_ENGINE_AIR_FILTER_STATUS,
            },
            rightQuery: {
              qualifier: BDQueryCriteriaType.IS_NULL,
              field: AssetReportFieldType.LOW_ENGINE_AIR_FILTER_STATUS,
            },
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.GREATER_THAN,
            field: AssetReportFieldType.ENGINE_AIR_FILTER_VALUE,
            values: [0],
          },
        } as BDQuery;
      default:
        return {} as BDQuery;
    }
  });
  return buildQueryTree(queries, 0, queries.length - 1, false);
};
const createDieselExhaustFluidFilterQuery = (
  engineAirFilterStatus: ReportDiagnosticState[]
) => {
  const queries = engineAirFilterStatus.map((status) => {
    switch (status) {
      case ReportDiagnosticState.CRITICAL:
        return {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: AssetReportFieldType.LOW_DIESEL_EXHAUST_FLUID,
          values: [true],
        } as BDQuery;
      case ReportDiagnosticState.GOOD:
        return {
          qualifier: BDQueryCriteriaType.IS_EQUAL,
          field: AssetReportFieldType.GOOD_DIESEL_EXHAUST_FLUID,
          values: [true],
        } as BDQuery;
      default:
        return {} as BDQuery;
    }
  });
  return buildQueryTree(queries, 0, queries.length - 1, false);
};
const createConnectivityBoardingStatusQuery = (
  connectivityBoardingStatus: ConnectivityStatusType[]
) => {
  const queries = connectivityBoardingStatus.map((status) => {
    switch (status) {
      case ConnectivityStatusType.PENDING_ONBOARDING:
        return {
          qualifier: BDQueryCriteriaType.OR,
          leftQuery: {
            qualifier: BDQueryCriteriaType.NOT_IN,
            field: AssetReportFieldType.CONNECTIVITY_PRIMARY_OWNERSHIP_STATUS,
            values: [OrganizationOwnershipStatus.CLAIMED],
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.IS_NULL,
            field: AssetReportFieldType.CONNECTIVITY_PRIMARY_OWNERSHIP_STATUS,
          },
        } as BDQuery;
      case ConnectivityStatusType.ONBOARDED:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: AssetReportFieldType.CONNECTIVITY_PRIMARY_OWNERSHIP_STATUS,
            values: [OrganizationOwnershipStatus.CLAIMED],
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.NOT_IN,
            field: AssetReportFieldType.CONNECTIVITY_PROVISIONING,
            values: [
              ProvisioningStatus.COMPLETED,
              ProvisioningStatus.IN_PROGRESS,
            ],
          },
        } as BDQuery;
      case ConnectivityStatusType.CONNECTED:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: AssetReportFieldType.CONNECTIVITY_PRIMARY_OWNERSHIP_STATUS,
            values: [OrganizationOwnershipStatus.CLAIMED],
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: AssetReportFieldType.CONNECTIVITY_PROVISIONING,
            values: [ProvisioningStatus.COMPLETED],
          },
        } as BDQuery;
      case ConnectivityStatusType.PENDING_CONNECTIVITY:
        return {
          qualifier: BDQueryCriteriaType.AND,
          leftQuery: {
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: AssetReportFieldType.CONNECTIVITY_PRIMARY_OWNERSHIP_STATUS,
            values: [OrganizationOwnershipStatus.CLAIMED],
          },
          rightQuery: {
            qualifier: BDQueryCriteriaType.IS_EQUAL,
            field: AssetReportFieldType.CONNECTIVITY_PROVISIONING,
            values: [ProvisioningStatus.IN_PROGRESS],
          },
        } as BDQuery;
    }
  });
  return buildQueryTree(queries, 0, queries.length - 1, false);
};
const createFilterQuery = (
  filterType: AssetListFilterCriteria | undefined,
  fieldNames: AssetRequestFieldNames,
  isChargeStatusFlagEnabled?: boolean
): (BDQuery | undefined)[] => {
  if (!filterType) {
    return [];
  }
  const queries = [];
  if (filterType.assetType?.length) {
    queries.push(createInQuery(fieldNames.type, filterType.assetType));
  }
  if (filterType.faultStatus?.length) {
    queries.push(
      createFaultStatusQuery(fieldNames.milState, filterType.faultStatus)
    );
  }
  if (filterType.lastCommunicatedStatus?.length) {
    queries.push(
      createLastCommunicatedQuery(
        filterType.lastCommunicatedStatus,
        AssetReportFieldType.LAST_COMMUNICATED
      )
    );
  }
  if (filterType.readyStatus?.length) {
    queries.push(createInQuery(fieldNames.ready, filterType.readyStatus));
  }
  if (filterType.tireStatus?.length) {
    queries.push(createTiresQuery(filterType.tireStatus));
  }
  if (
    (filterType.batteryChargingStatus?.length ||
      filterType.powerType?.length) &&
    isChargeStatusFlagEnabled
  ) {
    queries.push(
      createChargeStatusQuery(
        filterType.batteryChargingStatus,
        filterType.powerType,
        AssetReportFieldType.CHARGING_STATUS
      )
    );
  }
  if (filterType.fuelStatus?.length) {
    queries.push(createFuelQuery(filterType.fuelStatus, fieldNames.fuel));
  }
  if (filterType.batteryChargingStatus?.length && !isChargeStatusFlagEnabled) {
    queries.push(
      createChargingQuery(
        filterType.batteryChargingStatus,
        AssetReportFieldType.BATTERY_CHARGING
      )
    );
  }

  if (filterType.powerType?.length) {
    queries.push(
      createPowerTypeQuery(
        filterType.powerType,
        AssetReportFieldType.CHARGING_POWER_TYPE
      )
    );
  }
  if (filterType.batterySocStatus?.length) {
    queries.push(
      createFuelQuery(filterType.batterySocStatus, fieldNames.battery)
    );
  }
  if (filterType.brakeFluidStatus?.length) {
    queries.push(
      createDiagnosticQuery(filterType.brakeFluidStatus, fieldNames.brakeFluid)
    );
  }
  if (filterType.washerFluidStatus?.length) {
    queries.push(
      createDiagnosticQuery(
        filterType.washerFluidStatus,
        fieldNames.washerFluid
      )
    );
  }
  if (filterType.diagnosticsHealthStatus?.length) {
    queries.push(
      createDiagnosticsHealthQuery(
        filterType.diagnosticsHealthStatus,
        fieldNames.diagnosticHealth
      )
    );
  }
  if (filterType.availabilityStatus?.length) {
    queries.push(
      createAvailabilityQuery(
        filterType.availabilityStatus,
        fieldNames.availability
      )
    );
  }
  if (filterType.oilLifeStatus?.length) {
    queries.push(createOilLifeQuery(filterType.oilLifeStatus));
  }
  if (filterType.brakePadFrontStatus?.length) {
    queries.push(createBrakePadQuery(true, filterType.brakePadFrontStatus));
  }
  if (filterType.brakePadRearStatus?.length) {
    queries.push(createBrakePadQuery(false, filterType.brakePadRearStatus));
  }
  if (filterType.engineAirFilterStatus?.length) {
    queries.push(createEngineAirFilterQuery(filterType.engineAirFilterStatus));
  }
  if (filterType.connectivityBoardingStatus?.length) {
    queries.push(
      createConnectivityBoardingStatusQuery(
        filterType.connectivityBoardingStatus
      )
    );
  }
  if (filterType.dieselExhaustFluidStatus?.length) {
    queries.push(
      createDieselExhaustFluidFilterQuery(filterType.dieselExhaustFluidStatus)
    );
  }
  if (filterType.fieldActionTypeStatus?.length) {
    queries.push(createEqualQuery('type', filterType.fieldActionTypeStatus));
  }
  return queries;
};

const createAssignmentQuery = (
  withoutAssignment: boolean | undefined,
  fieldNames: AssetRequestFieldNames
): (BDQuery | undefined)[] => {
  if (!withoutAssignment) {
    return [];
  }
  const queries = [];
  queries.push(createIsNullQuery(fieldNames.hubId));
  queries.push(createIsNullQuery(fieldNames.fleetId));
  return queries;
};

const createOrgAssignmentQuery = (
  withoutOrgAssignment: boolean | undefined,
  fieldNames: AssetRequestFieldNames
): BDQuery | undefined => {
  if (!withoutOrgAssignment) {
    return undefined;
  }
  return createIsNullQuery(fieldNames.organizationId);
};

const buildContextQuery = (
  context: Partial<RouteParams>,
  fieldNames: AssetRequestFieldNames
) => {
  return context.fleetsId
    ? createEqualQuery(fieldNames.fleetId, [context.fleetsId])
    : context.hubsId
    ? createEqualQuery(fieldNames.hubId, [context.hubsId])
    : context.organizationsId
    ? createEqualQuery(fieldNames.organizationId, [context.organizationsId])
    : undefined;
};

export const buildAssetQuery = (
  config: Partial<AssetListSession> | undefined,
  context: Partial<RouteParams>,
  fieldNames: AssetRequestFieldNames,
  isChargeStatusFlagEnabled?: boolean,
  disableContextQuery?: boolean
): BDQuery | undefined => {
  const searchQuery = createSearchQuery(config?.searchCriteria, fieldNames);
  const filterQuery = createFilterQuery(
    config?.filterType,
    fieldNames,
    isChargeStatusFlagEnabled
  );
  const assignmentQuery = createAssignmentQuery(
    config?.withoutAssignment,
    fieldNames
  );
  const assetContextQuery = disableContextQuery
    ? undefined
    : buildContextQuery(context, fieldNames);
  const filteredQueries = [
    searchQuery,
    assetContextQuery,
    ...filterQuery,
    ...assignmentQuery,
    createOrgAssignmentQuery(config?.withoutOrganizationAssignment, fieldNames),
  ].filter((query) => query !== undefined) as BDQuery[];
  return createAssetQueryTree(filteredQueries);
};

export const buildAssetSort = (
  config: Partial<AssetListSession> | undefined,
  fieldNames: AssetRequestFieldNames
): BDSort[] | undefined => {
  if (!config?.sortOrder?.name) {
    return undefined;
  }
  const propertyName =
    fieldNames[config?.sortOrder?.name as keyof AssetRequestFieldNames] ||
    config?.sortOrder?.name;
  return [
    {
      direction: (
        config.sortOrder.direction || 'asc'
      ).toLocaleUpperCase() as BDSortDirection,
      properties: [propertyName],
    },
  ];
};

export const getLastCommunicatedDateRange = (
  status: LastCommunicatedStatus
): { start?: Date; end?: Date } => {
  const today: DateTime = DateTime.now();
  const offlineThreshold = today.toJSDate();
  switch (status) {
    case LastCommunicatedStatus.WITHIN_1HR:
      return {
        start: today.minus({ hour: 2 }).toJSDate(),
        end: offlineThreshold,
      };
    case LastCommunicatedStatus.WITHIN_1D: {
      return {
        start: today.minus({ hour: 48 }).toJSDate(),
        end: offlineThreshold,
      };
    }
    case LastCommunicatedStatus.WITHIN_1W:
    case LastCommunicatedStatus.WITHIN_M1W: {
      return {
        start: today.minus({ days: 8 }).toJSDate(),
        end: offlineThreshold,
      };
    }
  }
};

export const getConnectivityDateRange = (
  status: AssetConnectivityStatus
): { start?: Date; end?: Date } => {
  const today: DateTime = DateTime.now();
  const offlineThreshold = today
    .minus({ minutes: OFFLINE_MINUTES_THRESHOLD })
    .toJSDate();
  switch (status) {
    case AssetConnectivityStatus.OFFLINE_1H:
      return {
        start: today.minus({ hour: 1 }).toJSDate(),
        end: offlineThreshold,
      };
    case AssetConnectivityStatus.OFFLINE_24H: {
      return {
        start: today.minus({ hour: 24 }).toJSDate(),
        end: offlineThreshold,
      };
    }
    case AssetConnectivityStatus.OFFLINE_3D: {
      return {
        start: today.minus({ days: 3 }).toJSDate(),
        end: offlineThreshold,
      };
    }
    case AssetConnectivityStatus.OFFLINE_1W: {
      return {
        start: today.minus({ weeks: 1 }).toJSDate(),
        end: offlineThreshold,
      };
    }
    case AssetConnectivityStatus.OFFLINE: {
      return {
        end: offlineThreshold,
      };
    }
    case AssetConnectivityStatus.ONLINE: {
      return {
        start: offlineThreshold,
      };
    }
  }
};

const createComplianceFilterQuery = (
  isChargeStatusFlagEnabled: boolean,
  filterCriteria?: AssetComplianceFilterCriteria
) => {
  if (!filterCriteria) {
    return [];
  }
  const queries = [];
  if (filterCriteria.selectedAssetTypes?.length) {
    queries.push(
      createInQuery(
        AssetReportFieldType.ASSET_TYPE,
        filterCriteria.selectedAssetTypes
      )
    );
  }

  if (filterCriteria.selectedSafetyComplianceStatus) {
    queries.push(
      createSafetyCompliantQuery(
        [filterCriteria.selectedSafetyComplianceStatus],
        AssetComplianceFieldType.SAFETY_COMPLIANT,
        AssetComplianceFieldType.CHARGING_COMPLIANT,
        isChargeStatusFlagEnabled
      )
    );
  }
  return queries;
};
export const buildAssetComplianceQuery = (
  config: Partial<AssetComplianceSession> | undefined,
  context: Partial<RouteParams>,
  isChargeStatusFlagEnabled: boolean
): BDQuery | undefined => {
  const searchQuery = createSearchQuery(
    config?.searchCriteria,
    ASSET_REPORT_FIELD_NAMES
  );
  const assetContextQuery = buildContextQuery(
    context,
    ASSET_REPORT_FIELD_NAMES
  );
  const filterQuery = createComplianceFilterQuery(
    isChargeStatusFlagEnabled,
    config?.filterCriteria
  );
  const filteredQueries = [
    searchQuery,
    assetContextQuery,
    ...filterQuery,
  ].filter((query) => query !== undefined) as BDQuery[];
  return createAssetQueryTree(filteredQueries);
};
// To display the target SOC value depending upon charging status
export const getTargetSocValue = (
  targetSoc: number,
  chargingStatus: BatteryChargingStatus
): string => {
  const showTargetSoc =
    chargingStatus === BatteryChargingStatus.CHARGING ||
    chargingStatus === BatteryChargingStatus.COMPLETED;
  return showTargetSoc ? `${targetSoc}%` : DEFAULT_EMPTY_VALUE;
};
