import {
  PeripheralOnboardStatusApiResponse,
  PeripheralOnboardStatusResponse,
  PeripheralsOnboardApiResponse,
  PeripheralsOnboardResponse,
} from '~/common/models/peripheral.model';

export const mapPeripheralsOnboardResponseToPeripherals = (
  apiOnboard: PeripheralsOnboardApiResponse
): PeripheralsOnboardResponse => ({
  batchId: apiOnboard.batchId,
  organizationId: apiOnboard.organizationId,
});

export const mapPeripheralsOnboardStatusResponseToPeripherals = (
  apiResponse: PeripheralOnboardStatusApiResponse
): PeripheralOnboardStatusResponse => {
  return {
    id: apiResponse.id,
    status: apiResponse.status,
    error: apiResponse.error,
    parameters: {
      organizationId: apiResponse.parameters?.organizationId,
      peripheralType: apiResponse.parameters?.peripheralType,
      make: apiResponse.parameters?.make,
      model: apiResponse.parameters?.model,
      version: apiResponse.parameters?.version,
      fileName: apiResponse.parameters?.fileName,
    },
    details: apiResponse.details,
  };
};
