import { cx } from '@emotion/css';
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';

import ErrorIcon from '../../assets/error.svg?react';
import useStyles from './ChipInput.styles';
import { ChipErrorData, ChipInputErrorType } from './models/ChipInput.model';

type ChipInputProps = {
  className?: string;
  dataTestId?: string;
  delimiter?: string | RegExp;
  placeholder?: string;
  validationRegex?: RegExp;
  chipLimit?: number;
  helperText?: string;
  onChange?: (selectedChips: string[]) => void;
  onInputError?: (error: boolean, data?: ChipErrorData) => void;
};

const ChipInput: React.FC<ChipInputProps> = ({
  className,
  dataTestId,
  delimiter = ' ', // default to space
  placeholder,
  validationRegex = /.*/, // default to match anything
  chipLimit,
  helperText,
  onChange,
  onInputError,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [chips, setChips] = useState<string[]>([]);
  const [invalidChips, setInvalidChips] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const validateInput = (newChips: string[]) => {
    if (chipLimit && newChips.length > chipLimit) {
      const errorDetails: ChipErrorData = {
        type: ChipInputErrorType.CHIP_LIMIT,
        details: {
          maxLength: chipLimit,
          currentLength: newChips.length,
        },
      };
      setError(true);
      onInputError?.(true, errorDetails);
      return;
    }

    const invalidChips = newChips.filter((chip) => !validationRegex.test(chip));

    if (invalidChips.length > 0) {
      const errorDetails: ChipErrorData = {
        type: ChipInputErrorType.INVALID_FORMAT,
        details: {
          invalidChips: invalidChips,
        },
      };
      setInvalidChips(invalidChips);
      setError(true);
      onInputError?.(true, errorDetails);
      return;
    }

    setError(false);
    onInputError?.(false);
  };

  /**
   * Takes the user input, splits it based on the provided delimter, trims any extra spaces, and removes any empty values.
   * It will then check for invalid values that do not match the validation regex.
   * Once the user input is processed it sets the new chips and the invalid new chips and calls the callbacks to relay the new input.
   * @param {string[]} value string array of values provided by the input component.
   */
  const handleInputChange = (value: string[]) => {
    const newChips: string[] = value.flatMap((val) =>
      val
        .split(delimiter)
        .map((chip) => chip.trim())
        .filter((chip) => chip)
    );
    setChips(newChips);
    validateInput(newChips);
    onChange && onChange(newChips);
  };

  return (
    <Box className={className} data-testid={dataTestId}>
      <FormControl className={cx(classes.formControl)}>
        <Autocomplete
          clearIcon={false}
          multiple
          options={[]}
          freeSolo
          value={chips}
          onChange={(event, value) => {
            handleInputChange(value);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          sx={{
            alignItems: 'flex-start',
            border: `1px solid ${theme.new.color.line.border}`,
            borderRadius: 2,
            fontSize: 14,
            height: 160,
            overflow: 'scroll',
            padding: '16px 12px',
          }}
          renderTags={(value: readonly string[], getChipProps) =>
            value.map((option: string, index: number) => {
              const { key, ...chipProps } = getChipProps({ index });

              return (
                <Chip
                  variant="outlined"
                  label={option}
                  key={key}
                  {...chipProps}
                  className={cx(
                    classes.chip,
                    invalidChips.includes(option) && classes.invalidChip
                  )}
                  tabIndex={0}
                  data-testid={dataTestId && `${dataTestId}-chip-${index}`}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={placeholder}
              sx={{
                height: 128,
                width: '100%',
                '& .MuiInputBase-input': {
                  fontSize: '14px',
                },
                '& .MuiInputBase-root': {
                  '&::before': {
                    display: 'none',
                  },
                  '&::after': {
                    display: 'none',
                  },
                },
              }}
            />
          )}
        />
        {helperText && (
          <FormHelperText className={classes.helperTextWrapper}>
            <span className={cx(classes.helperText, { error })}>
              {error && <ErrorIcon />}
              <Typography variant="small_body_medium">{helperText}</Typography>
            </span>
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default ChipInput;
