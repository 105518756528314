import { Address } from '@gm-commercial/location-model';
import { User } from '@gm-commercial/profile-model';

import type { DtcSystems } from '~/features/assets/details/Dtc.model';

import { ApiAddressFields } from '../mappers/common.mappers';
import {
  BatteryChargingStatus,
  PowerType,
  ReportDiagnosticState,
  VehicleDetailsType,
} from './asset-report.model';
import {
  AssetRawLocation,
  Entity,
  Location,
  PartialEntity,
} from './common.model';
import { Fleet } from './fleet.model';
import { Hub } from './hub.model';
import { Organization } from './organization.model';

export type DoorStatus = 'OPEN' | 'CLOSED';
export type ConnectionStatus = 'ONLINE' | 'OFFLINE';
export type LockStatus = 'LOCKED' | 'UNLOCKED' | 'PENDING';
export type PowerStatus = 'ON' | 'OFF';

export enum DistanceUnit {
  KM = 'KM',
  MI = 'MI',
}

export enum EnergyUnit {
  kWh = 'kWh',
  MWh = 'MWh',
  GWh = 'GWh',
  kW = 'kW',
}

export enum EnergyEfficiency {
  KM = 'kWh/km',
  MI = 'kWh/mi',
}

export enum CapacityUnit {
  GALLON = 'gal',
  LITER = 'L',
}

export enum FuelEconomyUnit {
  MPG = 'mpg',
  L100K = 'L/100 km',
}

export enum TirePressureUnit {
  KPA = 'kPa',
  PSI = 'psi',
}

export enum SpeedUnit {
  MPH = 'mph',
  KPH = 'kph',
  MPS = 'mps',
}

export enum TemperatureUnit {
  F = 'F',
  C = 'C',
}

export enum AssetProductModel {
  ZEVO = 'Zevo',
  TRACE = 'Trace Move',
  PERI = 'Trace Grocery',
}
export enum AssetTypeModel {
  ZEVO = 'Zevo',
  TRACE = 'Trace',
  PERI = 'Peri',
}

export enum AssetType {
  EP = 'EP',
  VEHICLE = 'EV',
  PERI = 'PERI',
}

export enum ApiAssetType {
  EP1 = 'EP1',
  ELCV = 'ELCV',
  PERI = 'PERI',
}

export enum UpdateApiAssetType {
  VEHICLE = 'Vehicle',
}

export enum AssetConnectivityStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  OFFLINE_1H = 'offline_1h',
  OFFLINE_24H = 'offline_24h',
  OFFLINE_3D = 'offline_3d',
  OFFLINE_1W = 'offline_1w',
}

export enum AssetEventAction {
  POWER_ON = 'power_on',
  POWER_OFF = 'power_off',
  CHARGE = 'charge',
  DIAGNOSTIC = 'diagnostic',
  LOCK = 'lock',
  DOOR = 'door',
}

export type AssetFamilyType = Exclude<AssetType, AssetType.PERI>;

export const AssetFamilyTypeToAssetTypesMap: {
  [k in AssetFamilyType]: AssetType[];
} = {
  [AssetType.EP]: [AssetType.EP, AssetType.PERI],
  [AssetType.VEHICLE]: [AssetType.VEHICLE],
};

export const AssetFamilyTypeToApiTypeTypesMap: {
  [k in AssetFamilyType]: ApiAssetType[];
} = {
  [AssetType.EP]: [ApiAssetType.EP1, ApiAssetType.PERI],
  [AssetType.VEHICLE]: [ApiAssetType.ELCV],
};

export const AssetToApiTypeMap: { [k in AssetType]: ApiAssetType } = {
  [AssetType.EP]: ApiAssetType.EP1,
  [AssetType.VEHICLE]: ApiAssetType.ELCV,
  [AssetType.PERI]: ApiAssetType.PERI,
};

export const ApiToAssetTypeMap: { [k in ApiAssetType]: AssetType } = {
  [ApiAssetType.EP1]: AssetType.EP,
  [ApiAssetType.ELCV]: AssetType.VEHICLE,
  [ApiAssetType.PERI]: AssetType.PERI,
};

export const AssetTypeToModelMap: { [k in AssetType]: AssetProductModel } = {
  [AssetType.EP]: AssetProductModel.TRACE,
  [AssetType.VEHICLE]: AssetProductModel.ZEVO,
  [AssetType.PERI]: AssetProductModel.PERI,
};

export const AssetTypeToProductModelMap: { [k in AssetType]: AssetTypeModel } =
  {
    [AssetType.EP]: AssetTypeModel.TRACE,
    [AssetType.VEHICLE]: AssetTypeModel.ZEVO,
    [AssetType.PERI]: AssetTypeModel.PERI,
  };

export const AssetProductModelToTypeModelMap: {
  [k in AssetProductModel]: AssetTypeModel;
} = {
  [AssetProductModel.TRACE]: AssetTypeModel.TRACE,
  [AssetProductModel.ZEVO]: AssetTypeModel.ZEVO,
  [AssetProductModel.PERI]: AssetTypeModel.PERI,
};

export enum DiagnosticFlag {
  TIRE_LOW = 'tire_low',
  TIRE_FLAT = 'tire_flat',

  BATTERY_GREEN = 'battery_green',
  BATTERY_YELLOW = 'battery_yellow',
  BATTERY_RED = 'battery_red',

  BRAKE_PAD_LIFE_FRONT_GREEN = 'brake_pad_life_front_green',
  BRAKE_PAD_LIFE_FRONT_YELLOW = 'brake_pad_life_front_yellow',
  BRAKE_PAD_LIFE_FRONT_RED = 'brake_pad_life_front_red',

  BRAKE_PAD_LIFE_REAR_GREEN = 'brake_pad_life_rear_green',
  BRAKE_PAD_LIFE_REAR_YELLOW = 'brake_pad_life_rear_yellow',
  BRAKE_PAD_LIFE_REAR_RED = 'brake_pad_life_rear_red',

  ENGINE_AIR_FILTER_GREEN = 'engine_air_filter_green',
  ENGINE_AIR_FILTER_YELLOW = 'engine_air_filter_yellow',
  ENGINE_AIR_FILTER_RED = 'engine_air_filter_red',

  TIRE_LEFT_FRONT_GREEN = 'tire_left_front_green',
  TIRE_LEFT_FRONT_YELLOW = 'tire_left_front_yellow',
  TIRE_LEFT_FRONT_RED = 'tire_left_front_red',

  TIRE_LEFT_REAR_GREEN = 'tire_left_rear_green',
  TIRE_LEFT_REAR_YELLOW = 'tire_left_rear_yellow',
  TIRE_LEFT_REAR_RED = 'tire_left_rear_red',

  TIRE_RIGHT_FRONT_GREEN = 'tire_right_front_green',
  TIRE_RIGHT_FRONT_YELLOW = 'tire_right_front_yellow',
  TIRE_RIGHT_FRONT_RED = 'tire_right_front_red',

  TIRE_RIGHT_REAR_GREEN = 'tire_right_rear_green',
  TIRE_RIGHT_REAR_YELLOW = 'tire_right_rear_yellow',
  TIRE_RIGHT_REAR_RED = 'tire_right_rear_red',

  OIL_LIFE_GREEN = 'oil_life_green',
  OIL_LIFE_YELLOW = 'oil_life_yellow',
  OIL_LIFE_RED = 'oil_life_red',

  FLUID_LOW_BRAKE = 'fluid_low_brake',
  FLUID_LOW_WASHER = 'fluid_low_washer',
  FLUID_BRAKE_GREEN = 'fluid_brake_green',

  FLUID_WASHER_GREEN = 'fluid_washer_green',

  // DEPRECATED
  TEMP_CUBBY1_RED = 'temp_cubby1_red',
  TEMP_CUBBY2_RED = 'temp_cubby2_red',
  TEMP_CUBBY3_RED = 'temp_cubby3_red',
  TEMP_CUBBY4_RED = 'temp_cubby4_red',
  TEMP_CUBBY5_RED = 'temp_cubby5_red',
  TEMP_CUBBY6_RED = 'temp_cubby6_red',
  TEMP_CUBBY7_RED = 'temp_cubby7_red',
  TEMP_CUBBY8_RED = 'temp_cubby8_red',
  TEMP_CUBBY9_RED = 'temp_cubby9_red',
  TEMP_CUBBY1_YELLOW = 'temp_cubby1_yellow',
  TEMP_CUBBY2_YELLOW = 'temp_cubby2_yellow',
  TEMP_CUBBY3_YELLOW = 'temp_cubby3_yellow',
  TEMP_CUBBY4_YELLOW = 'temp_cubby4_yellow',
  TEMP_CUBBY5_YELLOW = 'temp_cubby5_yellow',
  TEMP_CUBBY6_YELLOW = 'temp_cubby6_yellow',
  TEMP_CUBBY7_YELLOW = 'temp_cubby7_yellow',
  TEMP_CUBBY8_YELLOW = 'temp_cubby8_yellow',
  TEMP_CUBBY9_YELLOW = 'temp_cubby9_yellow',
}

export enum AssetReportType {
  UTILIZATION = 'utilization',
  FLEET_READINESS = 'fleetReadiness',
  BATTERY = 'battery',
  TIRE_PRESSURE = 'tirePressure',
  FLUIDS = 'fluids',
  RECALLS = 'recalls',
}

export enum BatteryRange {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  UNKNOWN = 'unknown',
}

export enum DiagnosticSeverity {
  GOOD = 'good',
  INFO = 'info',
  IMPAIRED = 'impaired',
  CRITICAL = 'critical',
  UNKNOWN = 'unknown',
}

export const DIAGNOSTIC_SEVERITY_ORDER = {
  [DiagnosticSeverity.UNKNOWN]: 0,
  [DiagnosticSeverity.INFO]: 1,
  [DiagnosticSeverity.GOOD]: 1,
  [DiagnosticSeverity.IMPAIRED]: 2,
  [DiagnosticSeverity.CRITICAL]: 3,
} as const;

export enum DiagnosticState {
  CLEAR = 'clear',
  IMPAIRED = 'impaired',
  CRITICAL = 'critical',
}

export enum AssetOperationalStatus {
  IN_SHOP = 'inShop',
  OUT_OF_SERVICE = 'out_of_service',
  OFFLINE = 'offline', //TODO: Task 28664 Remove once logic is implemented to calculate from last communicated
  BROKEN_DOWN = 'brokenDown',
  CHARGING = 'charging',
  PARKED = 'parked',
  MOVING = 'moving',
  MOVING_NO_DIRECTION = 'movingNoDirection',
  STOPPED = 'stopped', //TODO: may be removed and only handled using Parked once BE supports it
}

export enum AssetDetailSubNav {
  OVERVIEW = 'overview',
  UTILIZATION = 'utilization',
  ALERTS = 'alerts',
  TRIP_HISTORY = 'tripHistory',
  TRIP_FAULT = 'tripFault',
}

export enum AssetTab {
  TRIP_HISTORY = 'tripHistory',
  FAULT_DATA = 'diagnostics',
  CURRENT_LOCATION = 'currentLocation',
}

export enum VehicleDetailsTab {
  OVERVIEW = 'overview',
  RECALLS = 'recalls',
}

export const ASSET_DETAIL_EP_SUB_NAV: AssetDetailSubNav[] = [
  AssetDetailSubNav.OVERVIEW,
  AssetDetailSubNav.TRIP_HISTORY,
];

export const ASSET_DETAIL_EV_SUB_NAV: AssetDetailSubNav[] = [
  AssetDetailSubNav.OVERVIEW,
  AssetDetailSubNav.UTILIZATION,
  AssetDetailSubNav.ALERTS,
  AssetDetailSubNav.TRIP_FAULT,
];

export interface DiagnosticSystem {
  subsystems: { type: string }[];
  type: DtcSystems;
}

export interface TelemetryStatus<T> {
  value: T;
  deviceTimestamp: string;
  severity?: DiagnosticSeverity;
  // Only used for DTCs
  systems?: DiagnosticSystem[] | null;
  mil?: boolean;
  name?: string;
  text?: string;
  recommendation?: string[];
}

export interface RecommendedTirePressures {
  front?: TelemetryStatus<number>;
  rear?: TelemetryStatus<number>;
}
export interface TirePressure {
  leftFront?: TelemetryStatus<number>;
  rightFront?: TelemetryStatus<number>;
  leftRear?: TelemetryStatus<number>;
  rightRear?: TelemetryStatus<number>;
}

export interface Odometer extends TelemetryStatus<number> {
  unitOfMeasure: DistanceUnit;
}

export type EstimatedRange = TelemetryStatus<number> & {
  unitOfMeasure: DistanceUnit;
};

export interface BatteryStatus {
  battery?: TelemetryStatus<number>;
  charge?: TelemetryStatus<boolean>;
  batteryRange?: BatteryRange;
}

export interface BrakePadStatuses {
  front?: TelemetryStatus<number>;
  rear?: TelemetryStatus<number>;
}

export type PeriCompartments = {
  temperatureStatus: {
    cubby1?: TelemetryStatus<number>;
    cubby2?: TelemetryStatus<number>;
    cubby3?: TelemetryStatus<number>;
    cubby4?: TelemetryStatus<number>;
    cubby5?: TelemetryStatus<number>;
    cubby6?: TelemetryStatus<number>;
    cubby7?: TelemetryStatus<number>;
    cubby8?: TelemetryStatus<number>;
    cubby9?: TelemetryStatus<number>;
  };
};
export interface AssetTelemetry {
  batteryStatus?: BatteryStatus;
  // TODO: The following 3 fields need to be mapped from ApiAsset
  brakePadStatuses?: BrakePadStatuses;
  brakeFluidStatus?: TelemetryStatus<boolean>;
  washerFluidStatus?: TelemetryStatus<boolean>;
  engineAirFilterStatus?: TelemetryStatus<number>;
  oilLifeStatus?: TelemetryStatus<number>;
  lock?: TelemetryStatus<LockStatus>;
  door?: TelemetryStatus<DoorStatus>;
  location?: TelemetryStatus<Location> & { displayAddress?: string };
  temperature?: TelemetryStatus<number>;
  diagnostics?: TelemetryStatus<string>;
  tirePressures?: TirePressure;
  odometer?: Odometer;
  estimatedRange?: EstimatedRange;
  expectedTirePressures?: RecommendedTirePressures;
  direction?: TelemetryStatus<number>;
  speedStatus?: TelemetryStatus<number>;
  thermalStatus?: DiagnosticSeverity;
  compartments?: PeriCompartments;
  chargingStatus?: TelemetryStatus<BatteryChargingStatus>;
  powerType?: TelemetryStatus<PowerType>;
  minutesToTargetSoc?: TelemetryStatus<number>;
  targetSoc?: TelemetryStatus<number>;
  fuelLevel?: TelemetryStatus<number>;
  lifetimeFuelEconomy?: TelemetryStatus<number>;
  // TODO: add lifetime efficiency to apiAsset and mapper once available
  lifetimeEfficiency?: TelemetryStatus<number>;
  engineRunTime?: TelemetryStatus<number>;
}

export type ApiLocation = {
  lat: number;
  lon: number;
};

export interface ApiAssetTelemetry {
  battery?: TelemetryStatus<number>;
  locked?: TelemetryStatus<boolean>;
  door?: TelemetryStatus<boolean>;
  location?: TelemetryStatus<ApiLocation>;
  temperature?: TelemetryStatus<number>;
  charge_active?: TelemetryStatus<boolean>;
  brake_pad_life_front_pct?: TelemetryStatus<number>;
  brake_pad_life_rear_pct?: TelemetryStatus<number>;
  engine_air_filter_life_in_pct?: TelemetryStatus<number>;
  oil_life?: TelemetryStatus<number>;
  c_diag?: TelemetryStatus<string>;
  odometer?: TelemetryStatus<number>;
  estimated_range?: TelemetryStatus<number | { value: number; uom: string }>;
  tire_driver_front_act?: TelemetryStatus<number>;
  tire_driver_rear_act?: TelemetryStatus<number>;
  tire_pass_front_act?: TelemetryStatus<number>;
  tire_pass_rear_act?: TelemetryStatus<number>;
  tires_front_exp?: TelemetryStatus<number>;
  tires_rear_exp?: TelemetryStatus<number>;
  speed?: TelemetryStatus<number>;
  direction?: TelemetryStatus<number>;
  temp_cubby1?: TelemetryStatus<number>;
  temp_cubby2?: TelemetryStatus<number>;
  temp_cubby3?: TelemetryStatus<number>;
  temp_cubby4?: TelemetryStatus<number>;
  temp_cubby5?: TelemetryStatus<number>;
  temp_cubby6?: TelemetryStatus<number>;
  temp_cubby7?: TelemetryStatus<number>;
  temp_cubby8?: TelemetryStatus<number>;
  temp_cubby9?: TelemetryStatus<number>;
  charging_session?: TelemetryStatus<BatteryChargingStatus>;
  charging_power_type?: TelemetryStatus<PowerType>;
  charging_minutes_remaining?: TelemetryStatus<number>;
  charging_target_soc?: TelemetryStatus<number>;
  fuel_level_in_pct?: TelemetryStatus<number>;
  lifetime_fuel_economy_in_kmpl?: TelemetryStatus<number>;
  engine_run_time_total_in_sec?: TelemetryStatus<number>;
}

export interface AssetState {
  connectivity: ConnectionStatus;
  diagnostic: string;
  availability: AssetServiceStatus;
  mil?: string;
  provisioning: ProvisioningStatus | null;
}

interface ApiAssetConfiguration {
  autolockTimeout: number | string;
  autolockEnabled: boolean | string;
  proximityUnlockEnabled: boolean | undefined; // TODO: US7741 for UI reference. Pending detail requirements
  chargingScheduleTargetChargeLevel?: number | string;
}

export interface AssetConfiguration {
  autolockTimeout: number | undefined;
  autolockEnabled: boolean | undefined;
  proximityUnlockEnabled: boolean | undefined;
  chargingScheduleEnabled: ChargingSchedule | undefined;
}

export interface AssetModel {
  model: string;
  version: string;
  apiType?: ApiAssetType;
  class?: AssetType;
}

export interface AssetFlags {
  diagnostics: AssetDiagnostics;
  ready: boolean | undefined;
  brakeFluidGreen?: boolean;
  lowBrakeFluid?: boolean;
  washerFluidGreen?: boolean;
  lowWasherFluid?: boolean;
  okBattery?: boolean;
  lowBattery?: boolean;
  brakePadLifeFrontGreen?: boolean;
  brakePadLifeFrontYellow?: boolean;
  brakePadLifeFrontRed?: boolean;
  brakePadLifeRearGreen?: boolean;
  brakePadLifeRearYellow?: boolean;
  brakePadLifeRearRed?: boolean;
  engineAirFilterGreen?: boolean;
  engineAirFilterYellow?: boolean;
  engineAirFilterRed?: boolean;
  tireLeftFrontGreen?: boolean;
  tireLeftFrontYellow?: boolean;
  tireLeftFrontRed?: boolean;
  tireLeftRearGreen?: boolean;
  tireLeftRearYellow?: boolean;
  tireLeftRearRed?: boolean;
  tireRightFrontGreen?: boolean;
  tireRightFrontYellow?: boolean;
  tireRightFrontRed?: boolean;
  tireRightRearGreen?: boolean;
  tireRightRearYellow?: boolean;
  tireRightRearRed?: boolean;
  oilLifeGreen?: boolean;
  oilLifeYellow?: boolean;
  oilLifeRed?: boolean;
  flatTire?: boolean;
  lowTire?: boolean;
  tempCubby1Red?: boolean;
  tempCubby2Red?: boolean;
  tempCubby3Red?: boolean;
  tempCubby4Red?: boolean;
  tempCubby5Red?: boolean;
  tempCubby6Red?: boolean;
  tempCubby7Red?: boolean;
  tempCubby8Red?: boolean;
  tempCubby9Red?: boolean;
  tempCubby1Yellow?: boolean;
  tempCubby2Yellow?: boolean;
  tempCubby3Yellow?: boolean;
  tempCubby4Yellow?: boolean;
  tempCubby5Yellow?: boolean;
  tempCubby6Yellow?: boolean;
  tempCubby7Yellow?: boolean;
  tempCubby8Yellow?: boolean;
  tempCubby9Yellow?: boolean;
}

export interface AssetTrip {
  id: string;
  start: TelemetryStatus<Location & { address: Address; id?: number }>;
  end: TelemetryStatus<Location & { address: Address; id?: number }>;
  tripId?: string;
  startEventId?: string;
  endEventId?: string;
  startOdometer?: number;
  endOdometer?: number;
}
export enum AssetFaultStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

type IsoTimestamp = string;

export interface AssetFault {
  id: string;
  code: string;
  severity: DiagnosticSeverity;
  deviceTimestamp: IsoTimestamp;
  status: AssetFaultStatus;
  mil: boolean;
  name: string;
  text?: string;
  system?: DtcSystems;
  recommendation?: string[];
}

export interface AssetFaultDisplay {
  numberOfFaults: number;
  hasCriticalFault: boolean;
  hasImpairedFault: boolean;
  numberOfCriticalFaults?: number;
}

//TODO: Review during integration
export interface ServiceStatus {
  value: AssetServiceStatus;
  lastUpdated?: {
    user: Partial<User>;
    timestamp: string;
  };
  reasons?: string[];
  notes?: string;
}

export interface AssetRecall {
  type: string;
  title: string;
  releaseDate: string;
}

export interface Asset extends Entity {
  type: AssetModel;
  status?: AssetTelemetry;
  state?: AssetState;
  vehicleType?: VehicleDetailsType;
  configuration?: AssetConfiguration;
  events?: AssetEvent[];
  locations?: AssetRawLocation[];
  organization?: PartialEntity<Organization>;
  hub?: PartialEntity<Hub>;
  fleet?: PartialEntity<Fleet>;
  organizationPrimaryOwnershipStatus: OrganizationOwnershipStatus | null;
  licensePlate?: string;
  creationDate?: string;
  lastUpdated?: string;
  flags?: AssetFlags;
  faults?: AssetFaultDisplay;
  pressureRecommendation?: boolean;
  faultsData?: AssetFault[];
  operationalStatus?: AssetOperationalStatus;
  utilization?: AssetUtilization[];
  raw?: Partial<ApiAsset>;
}
export interface AssetUtilization {
  utilizationDate: string;
  distanceDriven: number;
  timeDriven: number;
}
export type ApiType = {
  model: string;
  version?: string;
};
export type ApiAsset = Entity & {
  type: ApiType;
  status: ApiAssetTelemetry | null;
  configuration?: ApiAssetConfiguration | null;
  state?: AssetState | null;
  events?: AssetEvent[];
  organization?: PartialEntity<Organization>;
  organizationPrimaryOwnershipStatus: OrganizationOwnershipStatus | null;
  provisioning: ProvisioningStatus | null;
  hub?: PartialEntity<Hub>;
  fleet?: PartialEntity<Fleet>;
  licensePlate?: string;
  diagnostics?: AssetDiagnostics | null;
  creationDate?: string;
  lastUpdated?: string;
  vehicleType?: VehicleDetailsType;
};

export type ApiAssetRecall = {
  fieldActions: AssetRecall[];
};

/**
 * Utility type, flattens list-view asset status props onto Asset
 */
export type WithPartialTelemetry<T> = T &
  Pick<
    AssetTelemetry,
    | 'batteryStatus'
    | 'door'
    | 'lock'
    | 'estimatedRange'
    | 'expectedTirePressures'
    | 'thermalStatus'
  > &
  Partial<AssetFlags> &
  Partial<AssetState> &
  Partial<TirePressure> &
  Pick<BatteryStatus, 'charge'> & { tireStatus?: DiagnosticSeverity };

export type ActionModel =
  | { type: 'Door'; value: DoorStatus }
  | { type: 'Lock'; value: LockStatus }
  | { type: 'Power'; value: PowerStatus };

export type ActorModel =
  | { id: null; method: 'A'; name: 'Autolock' }
  | { id: string | null; method: 'A' | 'B' | 'C' | null; name: string };

export type ApiActionModel = { type: AssetEventAction; value: boolean };
export type ApiActorModel = {
  id: string | null;
  method: 'A' | 'B' | 'C' | null;
  name: string | null;
};
export interface AssetEventTelemetry {
  location?: Location;
  charge?: boolean;
  door?: 'OPEN' | 'CLOSED';
  lock?: 'LOCKED' | 'UNLOCKED';
  battery?: number;
  temperature?: number;
  diagnostics?: string;
  address?: Address;
  tripId?: string;
  startEventId?: string;
  endEventId?: string;
  tripStartOdometer?: number;
  tripEndOdometer?: number;
}

export interface ApiAssetEventTelemetry {
  location?: ApiLocation & { address?: ApiAddressFields };
  charge_active?: boolean;
  door?: boolean;
  locked?: boolean;
  battery?: number;
  temperature?: number;
  c_diag?: string;
  tripId?: string;
  startEventId?: string;
  endEventId?: string;
  tripStartOdometer?: number;
  tripEndOdometer?: number;
}

export interface AssetEvent {
  id: string;
  assetId: string;
  state: AssetEventTelemetry;
  timestamp: string;
  action?: ActionModel;
  actor?: ActorModel;
}

export interface ApiAssetLocation {
  metadata: { ts: string; dir: number; spd: number }[];
  coordinates: [];
}

export interface ApiGeoJSON {
  geometry: { coordinates: Array<Array<number>> };
  properties: {
    dir: Array<number>;
    spd: Array<number>;
    ts: Array<string>;
  };
}

export interface ApiAssetEvent {
  id: string;
  assetId: string;
  action: ApiActionModel;
  state: ApiAssetEventTelemetry;
  timestamp: string;
  actor?: ApiActorModel;
}

export enum AssetServiceStatus {
  IN_SERVICE = 'in_service',
  OUT_OF_SERVICE = 'out_of_service',
}

export enum AssetOutOfServiceReasons {
  BATTERY_ISSUE = 'batteryIssue',
  MECHANICAL_ISSUE = 'mechanicalIssue',
  TEMPERATURE_ISSUE = 'temperatureIssue',
  OTHER_ISSUE = 'otherIssue',
}

export enum AssetInServiceReasons {
  ISSUES_RESOLVED = 'issuesResolved',
  OTHER_REASON = 'otherReason',
}
export enum ChargingSchedule {
  ON = 'ON',
  OFF = 'OFF',
}

export type EditAssetStatusFormFields = {
  status: AssetServiceStatus | null;
  reasons?: (AssetOutOfServiceReasons | AssetInServiceReasons)[];
  notes?: string | null;
};

export type EditAssetFormFields = {
  name: string | null;
  organization: PartialEntity<Organization> | null;
  hub: PartialEntity<Hub> | null;
  fleet: PartialEntity<Fleet> | null;
};

export type EditVehicleFormFields = EditAssetFormFields & {
  licensePlate: string | null;
  desiredAvailability: AssetServiceStatus | null;
  currentAvailability: AssetServiceStatus | null;
};

export type AddAssetFormFields = {
  ePalletSerial: string;
  trackerSerial: string;
  type: AssetModel | null;
  organization?: PartialEntity<Organization> | null;
};
export type AddAssetFormErrors = { [k in keyof AddAssetFormFields]: string };
export type AddAssetForm = {
  id?: string;
} & AddAssetFormFields & { errors: AddAssetFormErrors };

export type AssetProtectedFormFields = { fan: string };

export type AssignAssetFormFields = {
  fleet: PartialEntity<Fleet> | null;
  organization: PartialEntity<Organization> | null;
};
export type AssignAssetFormErrors = {
  [k in keyof AssignAssetFormFields]: string;
};

export type AssetDiagnostics =
  | { [key: string]: TelemetryStatus<boolean> } // DTCs
  | {
      [key in DiagnosticFlag]: TelemetryStatus<boolean>; // known statuses
    };

export type AssetPubSubEventPayload = {
  id: string;
  diagnostics?: AssetDiagnostics;
  state?: AssetState;
  statuses?: Partial<
    Omit<ApiAssetTelemetry, 'location'> & {
      location?: TelemetryStatus<{ coordinates: [number, number] }>;
    }
  >;
};

// TODOS: Figure out what are the correct field types for asset query
export enum AssetSearchFieldType {
  ASSET_ID = 'sourceInstance.asset.id',
  ASSET_NAME = 'sourceInstance.asset.name',
  LICENSE_PLATE = 'sourceInstance.asset.licensePlate',
  FLEET_ID = 'sourceInstance.asset.fleet.id',
  FLEET_NAME = 'sourceInstance.asset.fleet.name',
  HUB_ID = 'sourceInstance.asset.hub.id',
  HUB_NAME = 'sourceInstance.asset.hub.name',
  ORG_ID = 'sourceInstance.asset.organization.id',
  ORG_NAME = 'sourceInstance.asset.organization.name',
}

export enum AssetExceptionStatus {
  LOW_TIRE = 'lowTire',
  FLAT_TIRE = 'flatTire',
  LOW_BRAKE_FLUID = 'lowBrakeFluid',
  LOW_WASHER_FLUID = 'lowWasherFluid',
  SEVERE_FAULT = 'severeFault',
  IMPAIRED_FAULT = 'impairedFault',
  FAULTS = 'faults',
  TEMP_CRITICAL = 'temperatureCritical',
  TEMP_IMPAIRED = 'temperatureImpaired',
  OUT_OF_SERVICE = 'outOfService',
}

export enum OrganizationOwnershipStatus {
  AVAILABLE = 'AVAILABLE',
  CLAIMING = 'CLAIMING',
  CLAIMED = 'CLAIMED',
  RELEASING = 'RELEASING',
}

export enum ProvisioningStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface AssetRequestFieldNames {
  id: string;
  name: string;
  licensePlate: string;
  organizationId: string;
  hubId: string;
  fleetId: string;
  type: string;
  ready: string;
  connectivity: string;
  battery: string;
  brakeFluid: string;
  washerFluid: string;
  tires: string;
  estimatedRange: string;
  faultCount: string;
  leftRearTire: string;
  leftFrontTire: string;
  rightFrontTire: string;
  rightRearTire: string;
  batteryCharging: string;
  safetyCompliant: string;
  milState: string;
  lastUpdated: string;
  availability: string;
  diagnosticHealth: string;
  fuel: string;
  oilLife: string;
  brakePadRear: string;
  brakePadFront: string;
  engineAirFilter: string;
  connectivityBoardingStatus: string;
}

export const ASSET_SEARCH_FIELD_NAMES: AssetRequestFieldNames = {
  id: AssetSearchFieldType.ASSET_ID,
  name: AssetSearchFieldType.ASSET_NAME,
  licensePlate: AssetSearchFieldType.LICENSE_PLATE,
  organizationId: AssetSearchFieldType.ORG_ID,
  hubId: AssetSearchFieldType.HUB_ID,
  fleetId: AssetSearchFieldType.FLEET_ID,
  //TODO: Investigate field names when searches is integrated
  type: '',
  ready: '',
  connectivity: '',
  battery: '',
  brakeFluid: '',
  washerFluid: '',
  tires: '',
  estimatedRange: '',
  faultCount: '',
  leftRearTire: '',
  leftFrontTire: '',
  rightFrontTire: '',
  rightRearTire: '',
  batteryCharging: '',
  safetyCompliant: '',
  milState: '',
  lastUpdated: '',
  availability: '',
  diagnosticHealth: '',
  fuel: '',
  oilLife: '',
  brakePadRear: '',
  brakePadFront: '',
  engineAirFilter: '',
  connectivityBoardingStatus: '',
};

export interface ApiAssetMapItem extends Entity {
  type: AssetModel;
  lastCommunicated?: string;
  location?: { lon: number; lat: number };
  operational?: {
    speed?: number;
    direction?: number;
  };
  battery?: {
    state?: ReportDiagnosticState;
    soc?: number;
    chargeState?: BatteryChargingStatus;
    estimatedRangedInKM?: number;
    chargePowerType?: PowerType;
  };
  connectivity?: string;
  brakeFluid?: ReportDiagnosticState;
  washerFluidState?: ReportDiagnosticState;
  tires?: ReportDiagnosticState;
  cubbyTempState: ReportDiagnosticState;
  ready?: boolean;
  faultCount?: number;
  milState?: ReportDiagnosticState;
  availability?: AssetServiceStatus;
}

export interface AssetMapItem extends Entity {
  model?: AssetProductModel;
  version?: string;
  operationalStatus?: AssetOperationalStatus;
  statusTime?: string;
  speed?: number;
  ready?: boolean;
  estimatedRange?: number;
  battery?: {
    isCharging?: boolean;
    range?: BatteryRange;
    value?: number;
    chargingStatus?: BatteryChargingStatus;
  };
  exceptions?: AssetExceptionStatus[];
  location?: Location;
  direction?: number;
  availability?: AssetServiceStatus;
}

export type AssignAssetItem = Entity & {
  type: AssetModel;
  organization?: PartialEntity<Organization>;
  hub?: PartialEntity<Hub>;
  fleet?: PartialEntity<Fleet>;
};
export interface SafetyComplianceSummaryVersions {
  version: string;
  compliant: number;
  nonCompliant: number;
  unknown: number;
}
export type SafetyComplianceSummary = {
  [k in ApiAssetType]?: {
    model: ApiAssetType;
    compliant: number;
    nonCompliant: number;
    unknown: number;
    versions: SafetyComplianceSummaryVersions[];
  };
};

export type ApiSafetyComplianceSummary = { models: SafetyComplianceSummary };

export interface SafetyComplianceStatusDetail {
  buckleToDriveEnabled?: boolean;
  speedWarningEnabled?: boolean;
  speedWarningThreshold?: boolean;
  supervisedDriveModeEnabled?: boolean;
  autolockEnabled?: boolean;
  autolockTimeout?: boolean;
}

export interface ChargingComplianceStatusDetail {
  chargingScheduleCompliant?: boolean;
  homeLocationCompliant?: boolean;
}

export interface SafetyComplianceDetail {
  value?: boolean;
  lastUpdated?: string;
  complianceStatus?: SafetyComplianceStatusDetail;
}
export interface ChargingScheduleCompliance {
  value?: boolean;
  lastUpdated?: string;
  complianceStatus?: ChargingComplianceStatusDetail;
}
export interface AssetComplianceDetail extends Entity {
  type: AssetModel;
  organization?: PartialEntity<Organization>;
  hub?: PartialEntity<Hub>;
  fleet?: PartialEntity<Fleet>;
  safetyCompliance?: SafetyComplianceDetail;
  chargingCompliance: ChargingScheduleCompliance;
}
