import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
  PERIPHERALS_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import { FormPayload } from '~/common/models/common.model';
import { BDError } from '~/common/models/error.model';
import {
  PeripheralOnboardStatusApiResponse,
  PeripheralsOnboardApiResponse,
  ValidPeripheralOnboardForm,
} from '~/common/models/peripheral.model';
import {
  addAcceptLanguageHeader,
  addOrganizationIdHeader,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

export enum PeripheralsActionType {
  ONBOARD_PERIPHERALS = 'peripherals/postPeripheralsOnboard',
  ONBOARD_STATUS_PERIPHERALS = 'peripherals/getOnboardStatus',
  POST_LINK_TO_VEHICLE = 'peripherals/postLinkToVehicle',
}

export type PeripheralsOnboardStatusParams = {
  id: string;
  organizationId: string;
};

const postPeripheralsOnboard = makeThunk(
  PeripheralsActionType.ONBOARD_PERIPHERALS,
  makePostPayloadCreator<
    ApiResponse<PeripheralsOnboardApiResponse>,
    FormPayload<ValidPeripheralOnboardForm, 'organizationsId'>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/peripherals/${API_VERSION_DEFAULTS.peripheralsOnboardingAPI}/onboard`,
    axiosOptions: (FormPayload, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          PERIPHERALS_API_CONFIG,
          FormPayload.formFields.organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ formFields, params }) => ({
      requestBody: { file: formFields.csvFile },
      requestParams: {
        organizationId: formFields.organizationsId,
        type: formFields.peripheralType,
        make: formFields.peripheralMake,
        model: formFields.peripheralModel,
      },
    }),
    responseAdapter: (response: any) => {
      if (response) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected Peripherals Onboard Response', {
        data: response,
      });
    },
  })
);

const getPeripheralsOnboardStatus = makeThunk(
  PeripheralsActionType.ONBOARD_STATUS_PERIPHERALS,
  makeGetPayloadCreator<
    ApiResponse<PeripheralOnboardStatusApiResponse>,
    PeripheralsOnboardStatusParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/peripherals/${API_VERSION_DEFAULTS.peripheralsOnboardingAPI}/batch-statuses/:id`,
    axiosOptions: ({ organizationId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationId),
        state.profile.currentLocale
      ),
    argAdapter: ({ id }) => {
      return {
        requestParams: {
          id,
        },
      };
    },
    responseAdapter: (response: any) => {
      if (response) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected Peripherals Onboard Response', {
        data: response,
      });
    },
  })
);

const CONFIG = DEFAULT_API_CONFIG;

type LinkParams = Pick<RouteParams, 'organizationsId'> & {
  id: string;
  vin: string;
};

const postLinkVehicle = makeThunk(
  PeripheralsActionType.POST_LINK_TO_VEHICLE,
  makePostPayloadCreator<ApiResponse, LinkParams>({
    url: ({ id }) =>
      `${globalThis.appConfig.apiBaseUrl}/peripherals/${API_VERSION_DEFAULTS.peripheralsAPI}/${id}/link-vehicle`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: ({ vin }) => {
      return {
        requestBody: {
          vin,
        },
      };
    },
  })
);

export const PERIPHERALS_ACTIONS = {
  postPeripheralsOnboard,
  getPeripheralsOnboardStatus,
  postLinkVehicle,
};
