export const NAV_DRAWER_MESSAGES = {
  settingsMenu: {
    'settings:settings': 'Settings',
    'settings:notifications.title': 'Notifications',
    'common:vehicles': 'Vehicles',
    'common:users': 'Users',
    'common:account': 'Account',
  },
  accountMenu: {
    'common:account': 'Account',
    'account:profile': 'Personal details',
    'account:personal': 'Personal',
    'account:preferences': 'Preferences',
    'account:languageAndRegion': 'Language & Region',
    'common:users': 'Users',
  },
  fleetMenu: {
    'common:home': 'Home',
    'common:vehicles': 'Vehicles',
    'common:list': 'List',
    'common:mrt:list': 'MRT List',
    'order:tableTitle': 'Orders',
    'purchasePrograms:purchasePrograms': 'Purchase Programs',
    'peripheral:navItem': 'Peripherals',
  },
  supportMenu: {
    'common:header.support': 'Support',
    'support:contact': 'Contact',
  },
  legacyMenu: {
    'common:map': 'Map',
    'dashboard:title': 'Dashboard',
    'common:organization': 'Organization',
    'common:hub': 'Hub',
    'common:fleet': 'Fleet',
    'common:organization_plural': 'Organizations',
    'common:asset_plural': 'Assets',
    'common:user_plural': 'Users',
    'common:alert_plural': 'Alerts',
    'common:footer.support': 'Support',
    'user:termsConditions.view': 'Terms and Conditions',
    'user:privacyStatement': 'Privacy Statement',
    'common:navDrawer.closeNav': 'Close navigation drawer',
    'common:documents.title': 'Documents',
    'common:dispatch.title': 'Dispatch Management',
    'insight:title': 'Insights',
    'peripheral:navItem': 'Peripherals',
    'inspection:assetsInspections': 'Assets Inspections',
    'connectedCameras:title': 'Connected Cameras',
    'purchasePrograms:purchasePrograms': 'Purchase Programs',
  },
};
