import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { useDispatch } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { VehicleOrdersActionType } from '~/features/orders/state/vehicleOrdersSlice.actions';

import { alertsApi } from '../alertsApi/alertsApi';
import { InspectionsActionType } from '../features/inspections/inspectionsSlice.model';
import { rootReducer, RootState } from './rootReducer';

/**
 * All reducers either omitted from persistence, or with customized persistence, must be blacklisted
 */
const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'auth',
    'profile',
    'organizations',
    'hubs',
    'fleets',
    'assets',
    'peripherals',
    'users',
    'map',
    'navDrawer',
    'dashboard',
    'alerts',
    'utilization',
    'pubSub',
    'dataIntegrations',
    'featureFlags',
    'orders',
    'inspections',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          `${InspectionsActionType.GET_INSPECTION_LONG_TERM_REPORT}/fulfilled`, // returns blob
          `${VehicleOrdersActionType.GET_ORDER_LIST_EXPORT}/fulfilled`, //returns blob
        ],
      },
    }).concat([alertsApi.middleware]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);

// Suppress return type warning as explicit return type provides little value here and is unreasonably complex
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
