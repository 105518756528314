import { EntityState } from '@reduxjs/toolkit';

import { OperationStatus } from '~/common/models/apis/apiResponse.model';
import {
  ListViewSession,
  OperationSession,
} from '~/common/models/common.model';
import {
  PeripheralListItem,
  PeripheralOnboardStatusResponse,
  PeripheralsOnboardResponse,
} from '~/common/models/peripheral.model';

export enum PeripheralsSessionViewType {
  LIST_VIEW = 'LIST_VIEW',
}

export interface PeripheralsListSession
  extends ListViewSession,
    OperationSession {
  importModalOpen?: boolean;
  onboardState: PeripheralsOnboardResponse;
  selectedIds?: string[];
  selectedPeripherals?: EntityState<PeripheralListItem>;
  allSelected?: boolean;
  onboardStateStatus: PeripheralOnboardStatusResponse;
}

export type PeripheralsSessionConfig = {
  [PeripheralsSessionViewType.LIST_VIEW]?: {
    [key: string]: Partial<PeripheralsListSession>;
  };
};

export interface PeripheralsState {
  items: EntityState<PeripheralListItem>;
  sessionConfigs: PeripheralsSessionConfig;
  operations: Record<string, OperationStatus>;
}

export interface SetSelectedPeripheralsPayload {
  sessionId: string;
  selectedPeripherals?: PeripheralListItem[];
  removeItems?: string[];
}
