import { Flex } from '@brightdrop/bd-ui';
import { useTranslations } from '@brightdrop/localization-client';
import { cx } from '@emotion/css';
import { Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

import CloseIcon from '../../assets/close.svg?react';
import CSVIcon from '../../assets/csvIcon.svg?react';
import ErrorIcon from '../../assets/error.svg?react';
import TrashIcon from '../../assets/trash.svg?react';
import UploadIcon from '../../assets/upload.svg?react';
import useStyles from './FileUpload.styles';

enum FileUploadStatus {
  Success = 'success',
  Error = 'error',
  NoFile = 'no_file',
}

export const KILOBYTE_IN_BYTES = 1000;
export const MEGABYTE_IN_BYTES = KILOBYTE_IN_BYTES * 1000;

const FILE_UPLOAD_MESSAGES = {
  'asset:addVehicleDialog.modalFileUploadErrorMessage':
    'Unable to upload file. Please follow these file requirements:',
  'asset:addVehicleDialog.modalFileUploadErrorTypeMessage':
    'File type is csv or xls',
  'asset:addVehicleDialog.modalFileUploadErrorSizeMessage':
    'Maximum file size is 200KB',
  'asset:addVehicleDialog.modalFileUploadErrorQuantityMessage':
    '1 File per upload',
  'asset:addVehicleDialog.modalFileUploadHelperText':
    'Supported file types: csv, xls. Maximum file size: 200 KB. 1 file per upload.',
  'asset:addVehicleDialog.modalFileUploadLabel': 'Drop file here or',
  'asset:addVehicleDialog.modalFileUploadLabelLink': 'browse files',
  fileSizes: {
    'common:bytes.unit.bytes_short': 'B',
    'common:bytes.unit.kilobytes_short': 'KB',
    'common:bytes.unit.megabytes_short': 'MB',
  },
};

export function transformFileSizeToLabel(
  fileSize: number,
  messages: typeof FILE_UPLOAD_MESSAGES.fileSizes
): string {
  if (fileSize < KILOBYTE_IN_BYTES) {
    return `${fileSize}${messages['common:bytes.unit.bytes_short']}`;
  }

  if (fileSize < MEGABYTE_IN_BYTES) {
    return `${(fileSize / KILOBYTE_IN_BYTES).toFixed(1)}${
      messages['common:bytes.unit.kilobytes_short']
    }`;
  }

  return `${(fileSize / MEGABYTE_IN_BYTES).toFixed(1)}${
    messages['common:bytes.unit.megabytes_short']
  }`;
}

type FileUploadProps = {
  onFileUpload: (file: File | null) => void; // Callback for file upload
  onUploadError: (error: boolean) => void; // Callback for upload error
  acceptedFileTypes: string[]; // Optional accepted file types
  name?: string;
  datatestId?: string;
};

const FileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  onUploadError,
  acceptedFileTypes,
  name,
  datatestId,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [status, setStatus] = useState<FileUploadStatus>(
    FileUploadStatus.NoFile
  );
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const { translations } = useTranslations(FILE_UPLOAD_MESSAGES);

  const maxSize = 200 * 1024; // Max size in bytes (200 KB)

  const successfulUploadMarkup = () => (
    <Flex className={classes.uploadFileDetails}>
      <Flex className={classes.fileTypeIcon}>
        <CSVIcon />
      </Flex>
      <Flex gap={16}>
        <Typography variant="label_regular">{uploadedFile?.name}</Typography>
        <Typography variant="label_regular">
          {transformFileSizeToLabel(
            uploadedFile?.size || 0,
            translations.fileSizes
          )}
        </Typography>
      </Flex>
      <Button
        aria-label={'reset-uploader-button'}
        className={classes.removeFileIcon}
        onClick={handleClear}
        data-testid={`reset-uploader-button`}
        startIcon={<TrashIcon stroke={theme.new.color.textPrimary} />}
      />
    </Flex>
  );

  const failedUploadMarkup = () => (
    <>
      <Flex className={classes.uploadFileDetails}>
        <Flex className={classes.fileTypeIcon}>
          <CSVIcon />
        </Flex>
        <Flex gap={16}>
          <Typography variant="label_regular">{uploadedFile?.name}</Typography>
          <Typography variant="label_regular">
            {transformFileSizeToLabel(
              uploadedFile?.size || 0,
              translations.fileSizes
            )}
          </Typography>
        </Flex>
        <Button
          aria-label={'reset-uploader-button'}
          className={classes.removeFileIcon}
          onClick={handleClear}
          data-testid={`reset-uploader-button`}
          startIcon={<CloseIcon stroke={theme.new.color.textPrimary} />}
        />
      </Flex>
      <Flex className={classes.errorFileRequirements}>
        <Flex className={classes.errorFileRequirementsLabel}>
          <ErrorIcon />
          <Typography variant="body_medium">
            {translations['asset:addVehicleDialog.modalFileUploadErrorMessage']}
          </Typography>
        </Flex>
        <ul>
          <li>
            <Typography variant="small_body_regular">
              {
                translations[
                  'asset:addVehicleDialog.modalFileUploadErrorTypeMessage'
                ]
              }
            </Typography>
          </li>
          <li>
            <Typography variant="small_body_regular">
              {
                translations[
                  'asset:addVehicleDialog.modalFileUploadErrorSizeMessage'
                ]
              }
            </Typography>
          </li>
          <li>
            <Typography variant="small_body_regular">
              {
                translations[
                  'asset:addVehicleDialog.modalFileUploadErrorQuantityMessage'
                ]
              }
            </Typography>
          </li>
        </ul>
      </Flex>
    </>
  );

  const handleChange = (file: File) => {
    // Check file type
    const fileType = file.type.split('/')[1].toUpperCase();
    setUploadedFile(file); // Set the file whether it's valid or not because we need the file details for the file details messaging

    if (!acceptedFileTypes.includes(fileType)) {
      setStatus(FileUploadStatus.Error);
      onUploadError(true);
      return;
    }

    // Check file size
    if (file.size > maxSize) {
      setStatus(FileUploadStatus.Error);
      onUploadError(true);
      return;
    }

    // If all checks pass
    setStatus(FileUploadStatus.Success);
    onUploadError(false);
    onFileUpload(file);
  };

  const handleClear = () => {
    setStatus(FileUploadStatus.NoFile);
    setUploadedFile(null);
    onUploadError(false);
    onFileUpload(null);
  };

  return (
    <Flex className={classes.fileUploadWrapper}>
      {(!uploadedFile || status === FileUploadStatus.Error) && ( // If there is no uploaded file present OR if the uploaded file is in error state show the upload form.
        <FileUploader
          name={name}
          data-testid={datatestId}
          handleChange={handleChange}
          types={acceptedFileTypes}
          hoverTitle={`${translations['asset:addVehicleDialog.modalFileUploadLabel']} ${translations['asset:addVehicleDialog.modalFileUploadLabelLink']}`}
        >
          <Flex className={classes.uploadContentWrapper}>
            <Flex className={classes.uploadLabel}>
              <UploadIcon height={24} width={24} />
              <Flex>
                <Typography variant="body_medium">
                  {translations['asset:addVehicleDialog.modalFileUploadLabel']}{' '}
                  <span className={classes.browseFilesText}>
                    {
                      translations[
                        'asset:addVehicleDialog.modalFileUploadLabelLink'
                      ]
                    }
                  </span>
                </Typography>
              </Flex>
            </Flex>
            <Typography
              variant="caption_regular"
              className={classes.uploadHelperText}
            >
              {translations['asset:addVehicleDialog.modalFileUploadHelperText']}
            </Typography>
          </Flex>
        </FileUploader>
      )}
      {status !== FileUploadStatus.NoFile && (
        <Flex className={cx(classes.uploadMessageWrapper, status)}>
          {status === FileUploadStatus.Success && successfulUploadMarkup()}
          {status === FileUploadStatus.Error && failedUploadMarkup()}
        </Flex>
      )}
    </Flex>
  );
};

export default FileUpload;
