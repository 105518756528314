import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useContext, useMemo } from 'react';

import { FeatureFlag } from '../models/featureFlags.model';
import FeatureFlagsProviderContext, {
  FeatureFlagsProviderContextProps,
} from '../providers/FeatureFlagsProviderContext';

const useFeatureFlags = <T>(): {
  ready: boolean;
  featureFlags?: FeatureFlag[];
  getFlag: (name: string) => FeatureFlag | undefined;
  setContext: (name: string, context: T) => void;
} => {
  const flags = useFlags();

  const { ready, setContext } = useContext<FeatureFlagsProviderContextProps<T>>(
    FeatureFlagsProviderContext
  );

  const featureFlags = useMemo(
    () =>
      flags
        ? Object.entries(flags).map(
            ([name, value]) =>
              ({
                name,
                value,
              } as FeatureFlag)
          )
        : [],
    [flags]
  );

  const getFlag = useCallback(
    (name: string) => {
      const value = flags[name];

      // Return undefined if the value is null.
      if (value == null) return undefined;

      // Return the value if not null.
      return value;
    },
    [flags]
  );

  return { featureFlags, getFlag, ready, setContext };
};

export default useFeatureFlags;
