import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  fileUploadWrapper: {
    flexDirection: 'column',
    gap: 10,
  },
  uploadContentWrapper: {
    alignItems: 'center',
    background: theme.new.color.inputBackground,
    border: `2px dashed ${theme.new.color.grey[400]}`,
    borderRadius: 8,
    flexDirection: 'column',
    height: 170,
    justifyContent: 'space-between',
    maxWidth: '100%',
    padding: 12,
    width: '100%',
  },
  uploadLabel: {
    alignItems: 'center',
    flexDirection: 'column',
    gap: 10,
    justifyContent: 'center',
    marginTop: 35,
  },
  browseFilesText: {
    color: theme.new.color.brandPrimary,
  },
  uploadHelperText: {
    color: theme.new.color.textSecondary,
  },
  uploadMessageWrapper: {
    borderRadius: 10,
    gap: 32,
    flexDirection: 'column',
    padding: 20,
    '&.success': {
      backgroundColor: theme.new.color.success[100],
    },
    '&.error': {
      backgroundColor: theme.new.color.danger[100],
    },
  },
  uploadFileDetails: {
    alignItems: 'center',
    flex: 1,
  },
  errorFileRequirements: {
    backgroundColor: theme.new.color.danger[100],
    flex: 1,
    flexDirection: 'column',
  },
  errorFileRequirementsLabel: {
    alignItems: 'center',
    color: theme.new.color.danger[600],
    gap: 8,
    svg: {
      '& *': {
        stroke: theme.new.color.danger[600],
      },
    },
  },
  fileTypeIcon: {
    marginInlineEnd: 8,
  },
  removeFileIcon: {
    height: 18,
    marginInlineStart: 'auto',
    minWidth: 18,
    padding: 0,
    width: 18,
  },
}));

export default useStyles;
