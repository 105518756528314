import { createSlice } from '@reduxjs/toolkit';

import { PERIPHERALS_ACTIONS } from './state/peripheralsSlice.actions';
import { INITIAL_PERIPHERALS_STATE } from './state/peripheralsSlice.constants';
import {
  PERIPHERALS_EXTRA_REDUCER_BUILDERS,
  PERIPHERALS_REDUCERS,
} from './state/peripheralsSlice.reducers';

const {
  reduceSetPeripheralsSessionConfig,
  reduceSetSelectedPeripherals,
  reduceResetPeripheralsSessionConfig,
} = PERIPHERALS_REDUCERS;

export const peripheralsSlice = createSlice({
  name: 'peripherals',
  initialState: INITIAL_PERIPHERALS_STATE,
  reducers: {
    setPeripheralsSessionConfig: reduceSetPeripheralsSessionConfig,
    resetPeripheralsSessionConfig: reduceResetPeripheralsSessionConfig,
    setSelectedPeripherals: reduceSetSelectedPeripherals,
  },
  extraReducers: (builder) => {
    PERIPHERALS_EXTRA_REDUCER_BUILDERS.buildPostLinkVehicleReducer(builder);
    PERIPHERALS_EXTRA_REDUCER_BUILDERS.buildOnboardPeripheralsReducer(builder);
    PERIPHERALS_EXTRA_REDUCER_BUILDERS.buildOnboardPeripheralsStatusReducer(
      builder
    );
  },
});

export const {
  postLinkVehicle,
  postPeripheralsOnboard,
  getPeripheralsOnboardStatus,
} = PERIPHERALS_ACTIONS;
export const {
  setPeripheralsSessionConfig,
  resetPeripheralsSessionConfig,
  setSelectedPeripherals,
} = peripheralsSlice.actions;

export const peripheralsReducer = peripheralsSlice.reducer;
