import { AppConfig, AppEnv, BDEnvironment } from '../models/appConfig.model';

const CONFIG_KEYS: {
  [key in typeof process.env.NODE_ENV as string]: any;
} = {
  production: 'bd_config' as const,
  development: 'local_config' as const,
  test: 'local_config' as const,
};

const environment = process.env.NODE_ENV;
const windowConfig: BDEnvironment | undefined =
  (CONFIG_KEYS[environment as string] &&
    window[CONFIG_KEYS[environment as string]]) ||
  undefined;

const assignConfig = (key: keyof BDEnvironment) =>
  (windowConfig || ({} as BDEnvironment))[key] || '';

export const loadAppConfig = (): AppConfig => {
  return {
    rawConfig: windowConfig,
    env: AppEnv[
      assignConfig('REACT_APP_ENV').toUpperCase() as keyof typeof AppEnv
    ],
    version: assignConfig('REACT_APP_VERSION'),
    apiBaseUrl: assignConfig('REACT_APP_API_BASE_URL'),
    mapKey: assignConfig('REACT_APP_MAP_KEY'),
    instrumentationKey: assignConfig('REACT_APP_INSTRUMENTATION_KEY'),
    instrumentationEnv: assignConfig('REACT_APP_INSTRUMENTATION_ENV'),
    instrumentationAppId: assignConfig('REACT_APP_INSTRUMENTATION_APP_ID'),
    instrumentationClientToken: assignConfig(
      'REACT_APP_INSTRUMENTATION_CLIENT_TOKEN'
    ),
    featureFlagProviderKey: assignConfig('REACT_APP_LAUNCH_DARKLY_KEY'),
    auth: {
      authority: assignConfig('REACT_APP_AZURE_AUTHORITY'),
      cdiAuthority: assignConfig('REACT_APP_AZURE_CDI_AUTHORITY'),
      knownAuthority: assignConfig('REACT_APP_AZURE_KNOWN_AUTHORITY'),
      clientId: assignConfig('REACT_APP_AZURE_CLIENT_ID'),
      redirectUri: assignConfig('REACT_APP_AZURE_REDIRECT_URI'),
      staticLoginRedirectUri: assignConfig(
        'REACT_APP_STATIC_LOGIN_REDIRECT_PAGE'
      ),
      cdiLogoutBaseUrl: assignConfig('REACT_APP_CDI_LOGOUT_BASE_URL'),
    },
    servicePortalUrl: assignConfig('REACT_APP_SERVICE_PORTAL_URL'),
    mfs: {
      supportEntryUrl: assignConfig('MF_SUPPORT_ENTRY_URL'),
    },
  };
};
