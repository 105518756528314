import { AppEnv } from '../constants/common.constant';
import { makeThunkCreatorConfig } from '../utils/store/thunk.helper';

export const DEFAULT_API_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const CONTENT_TYPE_HEADER = 'Content-Type';
export const ACCEPT_LANGUAGE_HEADER = 'Accept-Language';
export const CONTENT_TYPE_VALUE = 'application/json';
export const CONTENT_TYPE_FILE_VALUE = 'multipart/form-data';

export const API_VERSION_DEFAULTS = {
  default: '2021-11-15',
  alertsAPI: '2021-11-15',
  alertsSubscriptionsUpdateAPI: '2022-07-01',
  alertsListAPI: '2023-12-01',
  utilizationAPI: '2022-05-24',
  assetComplianceAPI: '2021-11-15',
  assetDetailsAPI: '2022-05-24',
  assetRecallsAPI: '2024-09-11',
  assetLocationsAPI: '2023-11-07',
  assetHistoryAPI: '2023-12-08',
  fleetAPI: '2024-09-26',
  coldChainFleetAPI: '2023-05-09',
  orgTypeAPI: '2023-07-17',
  insightsAPI: '2022-05-31',
  ordersAddEditAPI: '2023-08-15',
  orderDefaultAPI: '2023-05-23',
  batteryReadinessAPI: '2023-08-16',
  inspectionsAPI: '2023-08-07',
  assetCountSummaryAPI: '2023-11-22',
  dispatchAPI: '2023-11-16',
  vehicleOrdersAPI: '2024-06-01',
  validateSsoEmailAPI: '2024-07-18',
  vehicleListOverviewAPI: '2024-10-08',
  competitiveAssistanceProgramAPI: '2024-10-08',
  recallSummary: '2024-09-11',
  peripheralsOnboardingAPI: '2024-10-08',
  peripheralsAPI: '2024-10-23',
  recallsAPI: '2024-10-23',
};

export const DEFAULT_API_CONFIG = makeThunkCreatorConfig({
  timeout:
    globalThis.appConfig.env === AppEnv.DEV ||
    globalThis.appConfig.env === AppEnv.QA
      ? 15000
      : 5000,
  retries: 2,
  delay: 100,
  customHeaders: {
    [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE,
  },
});
export const INSPECTION_API_CONFIG = makeThunkCreatorConfig({
  timeout:
    globalThis.appConfig.env === AppEnv.DEV ||
    globalThis.appConfig.env === AppEnv.QA
      ? 15000
      : 5000,
  retries: 2,
  delay: 100,
  customHeaders: {
    [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE,
    Accept: ' ',
  },
});
export const ALERTS_API_CONFIG = makeThunkCreatorConfig({
  timeout: 15000,
  retries: 2,
  delay: 100,
  customHeaders: {
    [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE,
  },
});

export const ASSETS_API_BASE_CONFIG = makeThunkCreatorConfig({
  timeout:
    globalThis.appConfig.env === AppEnv.DEV ||
    globalThis.appConfig.env === AppEnv.QA
      ? 15000
      : 5000,
  retries: 2,
  delay: 100,
  customHeaders: {
    [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE,
  },
});

export const UTILIZATIONS_API_CONFIG = makeThunkCreatorConfig({
  timeout: 40000,
  retries: 2,
  delay: 100,
  customHeaders: {
    [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE,
  },
});

export const PERIPHERALS_API_CONFIG = makeThunkCreatorConfig({
  timeout:
    globalThis.appConfig.env === AppEnv.DEV ||
    globalThis.appConfig.env === AppEnv.QA
      ? 15000
      : 5000,
  retries: 2,
  delay: 100,
  customHeaders: {
    [CONTENT_TYPE_HEADER]: CONTENT_TYPE_FILE_VALUE,
  },
});

export const BD_ORGANIZATION_HEADER_NAME = 'x-bd-organization';
export const BD_HUB_HEADER_NAME = 'x-bd-hub';
export const BD_FLEET_HEADER_NAME = 'x-bd-fleet';
export const CONTINUATION_TOKEN_HEADER_NAME = 'continuationToken';
